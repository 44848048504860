import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

// Admin Requests
const getAdminRegistrationListAPI = async () => {
    const url = `${DOMAIN}admin/registration/list`;
    const response = await apiFetch({ url });
    return response;
};
const getAdminRegistrationAPI = async ({ regID, orgID }) => {
    const url = `${DOMAIN}admin/registration?orgID=${orgID}&id=${regID}`;
    const response = await apiFetch({ url });
    return response;
};
const getAdminCCBListAPI = async () => {
    const url = `${DOMAIN}admin/ccb/list`;
    const response = await apiFetch({ url });
    return response;
};
const updateAdminRegistrationAPI = async ({ regID, orgID, data }) => {
    const url = `${DOMAIN}admin/registration?orgID=${orgID}&id=${regID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const updateAdminRegistrationNoteAPI = async ({ regID, orgID, noteID, data }) => {
    const url = `${DOMAIN}admin/registration/note?orgID=${orgID}&regID=${regID}&noteID=${noteID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const updateAdminCCBAPI = async ({ ccbID, orgID, data }) => {
    const url = `${DOMAIN}admin/ccb?orgID=${orgID}&id=${ccbID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const refreshAdminCCBAPI = async ({ ccbID, orgID }) => {
    const url = `${DOMAIN}admin/ccb?orgID=${orgID}&id=${ccbID}`;
    const response = await apiFetch({ url });
    return response;
};
const updateAdminTitleBulkAPI = async ({ orgID, data }) => {
    const url = `${DOMAIN}admin/title/bulk?orgID=${orgID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const getAdminUserListAPI = async () => {
    const url = `${DOMAIN}admin/user/list`;
    const response = await apiFetch({ url });
    return response;
};
const getAdminFeeListAPI = async () => {
    const url = `${DOMAIN}admin/fee/list`;
    const response = await apiFetch({ url });
    return response;
};
const adminSyncStripeAPI = async () => {
    const url = `${DOMAIN}admin/fee/sync`;
    const response = await apiFetch({ method: 'PUT', url });
    return response;
};

export {
    getAdminRegistrationListAPI,
    getAdminRegistrationAPI,
    updateAdminRegistrationAPI,
    getAdminUserListAPI,
    getAdminCCBListAPI,
    updateAdminCCBAPI,
    refreshAdminCCBAPI,
    updateAdminTitleBulkAPI,
    updateAdminRegistrationNoteAPI,
    getAdminFeeListAPI,
    adminSyncStripeAPI
};
