import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// page routing
const HomePage = Loadable(lazy(() => import('views/home-page')));
const SettingsPage = Loadable(lazy(() => import('views/settings-page')));
const PortfolioPage = Loadable(lazy(() => import('views/portfolio-page')));
const RegisterPage = Loadable(lazy(() => import('views/registration')));
const RegisterTitle = Loadable(lazy(() => import('views/registration/registration-form')));
const RegistrationSelect = Loadable(lazy(() => import('views/registration/registration-select/RegistrationSelectPage')));
const AssessPage = Loadable(lazy(() => import('views/assess/assess-page')));
const AssessListPage = Loadable(lazy(() => import('views/assess/assess-list')));
const CalendarPage = Loadable(lazy(() => import('views/calendar-page')));
const ImportPage = Loadable(lazy(() => import('views/import-page')));
const ActionListPage = Loadable(lazy(() => import('views/enforce/enforce-action-list')));
const EnforceActionSelectPage = Loadable(lazy(() => import('views/enforce/enforce-action-select')));
const EnforceActionPage = Loadable(lazy(() => import('views/enforce/enforce-action')));
const CCBPlaceholcer = Loadable(lazy(() => import('views/enforce/ccb-page/CCBPlaceholder')));
const AttorneyPlaceholder = Loadable(lazy(() => import('views/enforce/attorney-page/AttorneyPlaceholder')));
const AttorneyContactCreatePage = Loadable(lazy(() => import('views/enforce/attorney-page/AttorneyContactCreate')));
const ProjectPage = Loadable(lazy(() => import('views/project-page')));
const TitlePage = Loadable(lazy(() => import('views/title-page')));
const LearningPage = Loadable(lazy(() => import('views/learning-page')));
const RegistrationGuidePage = Loadable(lazy(() => import('views/registration-guide-page')));
const SupportPage = Loadable(lazy(() => import('views/support-page')));
const EmailVerificationPage = Loadable(lazy(() => import('views/email/EmailVerification')));
const SummaryPage = Loadable(lazy(() => import('views/assess/summary-page/Summary')));
const AccessDenied = Loadable(lazy(() => import('views/misc/AccessDenied')));
const NotFound = Loadable(lazy(() => import('views/misc/NotFound')));
const TermsOfUse = Loadable(lazy(() => import('views/authentication/Terms')));
const PaymentStatus = Loadable(lazy(() => import('views/settings-page/stripe/PaymentStatus')));

const TestPage = Loadable(lazy(() => import('views/TestPage2')));

// ===========================|| MAIN ROUTING FOR USERS ||=========================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '*',
            element: <NotFound />
        },
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/settings',
            element: <SettingsPage />
        },
        {
            path: '/portfolio',
            element: <PortfolioPage />
        },
        {
            path: '/register/list',
            element: <RegisterPage />
        },
        {
            path: '/register/select',
            element: <RegistrationSelect />
        },
        {
            path: '/register/title',
            element: <RegisterTitle />
        },
        {
            path: '/assess',
            element: <AssessPage />
        },
        {
            path: '/assess/list',
            element: <AssessListPage />
        },
        {
            path: '/enforce/list',
            element: <ActionListPage />
        },
        {
            path: '/enforce/action',
            element: <EnforceActionPage />
        },
        {
            path: 'enforce/attorney/create',
            element: <AttorneyContactCreatePage />
        },
        {
            path: '/ccb-placeholder',
            element: <CCBPlaceholcer />
        },
        {
            path: '/attorney-placeholder',
            element: <AttorneyPlaceholder />
        },
        {
            path: '/enforce',
            element: <EnforceActionSelectPage />,
            children: [{ path: ':type', element: <EnforceActionSelectPage /> }]
        },
        {
            path: '/calendar',
            element: <CalendarPage />
        },
        {
            path: '/import',
            element: <ImportPage />
        },
        {
            path: '/learn',
            element: <LearningPage />
        },
        {
            path: '/registration-guide',
            element: <RegistrationGuidePage />
        },
        {
            path: '/support',
            element: <SupportPage />
        },
        {
            path: '/title',
            element: <TitlePage />,
            children: [{ path: ':id', element: <TitlePage /> }]
        },
        {
            path: '/project',
            element: <ProjectPage />,
            children: [{ path: ':id', element: <ProjectPage /> }]
        },
        {
            path: '/email',
            element: <EmailVerificationPage />
        },
        {
            path: '/summary',
            element: <SummaryPage />,
            children: [{ path: ':id', children: [{ path: ':idx', element: <SummaryPage /> }] }]
        },
        {
            path: '/denied',
            element: <AccessDenied />
        },
        {
            path: '/terms',
            element: <TermsOfUse />
        },
        {
            path: '/test',
            element: <TestPage />
        },
        {
            path: '/payment-setup-complete',
            element: <PaymentStatus />
        }
    ]
};

export default MainRoutes;
