import user from './user';
import admin from './admin';
import titan from './titan';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = ({ isAdmin, isTitan, email }) => {
    const items = [];
    if (isAdmin) {
        items.push(admin);
    } else if (isTitan) {
        items.push(titan);
    } else {
        items.push(user);
    }
    return { items };
};

export default menuItems;
