import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAdminUserListAPI, getAdminFeeListAPI, adminSyncStripeAPI } from 'requests/admin';

// ==============================|| REDUX STORE: ADMIN DATA ||============================== //

const getUsers = createAsyncThunk('admin/getUsers', async () => {
    const response = await getAdminUserListAPI();
    return response;
});

const getFees = createAsyncThunk('admin/getFees', async () => {
    const response = await getAdminFeeListAPI();
    return response;
});

const syncStripeData = createAsyncThunk('admin/syncStripe', async () => {
    const response = await adminSyncStripeAPI();
    return response;
});

const initialState = {
    usersInitialized: false,
    feesInitialized: false,
    users: [],
    fees: []
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        logout(state) {
            // state = initialState;
            state.usersInitialized = false;
            state.users = [];
            state.feesInitialized = false;
            state.fees = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.fulfilled, (state, action) => {
                state.usersInitialized = true;
                state.users = [...action.payload];
            })
            .addCase(getFees.fulfilled, (state, action) => {
                state.feesInitialized = true;
                state.fees = [...action.payload];
            })
            .addCase(syncStripeData.fulfilled, (state, action) => {
                state.fees = [...action.payload];
            });
    }
});

const usersInitialized = (state) => state?.admin?.usersInitialized;
const users = (state) => state?.admin?.users;
const feesInitialized = (state) => state?.admin?.feesInitialized;
const fees = (state) => state?.admin?.fees;
const { logout } = adminSlice.actions;

export { usersInitialized, users, getUsers, syncStripeData, feesInitialized, fees, getFees, logout };
export default adminSlice.reducer;
