import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getTitleAPI,
    getAdminTitleAPI,
    getTitleListAPI,
    updateTitleAPI,
    deleteTitleAPI,
    updateBulkTitlesAPI,
    createTitleAPI,
    createBulkTitlesAPI,
    addInfringementAPI
} from 'requests/portfolio';
import { getFileURLAPI, uploadFileWithURLAPI } from 'requests/file';
import { addActionAPI, sendCNDEmailAPI, sendCND2EmailAPI, sendDMCAEmailAPI, addActionNoteAPI, updateActionAPI } from 'requests/enforce';
import { sendAttorneyEmailAPI, sendAttorneyEmail2API } from 'requests/attorney';
import { updateAdminTitleBulkAPI } from 'requests/admin';
import { createEventAPI } from 'requests/event';
import config from 'config';

// ==============================|| REDUX STORE: TITLE DATA ||============================== //

const createTitle = createAsyncThunk('title/create', async ({ projectID, data }) => {
    const response = await createTitleAPI({ projectID, data });
    return response;
});

const addInfringement = createAsyncThunk('title/addInfringement', async ({ titleID, infringementData }) => {
    // Upload any files
    const fileURLs = [];
    const { files } = infringementData;
    if (files && files.length > 0) {
        // Get s3 upload URLs
        const promises1 = [];
        for (let i = 0; i < files.length; i += 1) {
            const file = files[i];
            const fileName = file.name;
            const path = 'titles/infringements';
            const mimeType = file.type;
            promises1.push(getFileURLAPI({ fileName, path, mimeType, fileRef: i.toString() }));
        }
        const awsURLs = await Promise.all(promises1);

        // Upload to s3
        const promises2 = [];
        awsURLs.forEach((response) => {
            const { fileRef, url } = response;
            fileURLs.push(url);
            const file = files[parseInt(fileRef, 10)];
            promises2.push(uploadFileWithURLAPI({ url, file }));
        });
        await Promise.all(promises2);
    }

    // Update title record with infringement data (replacing files with URL references)
    const data = { ...infringementData, files: fileURLs };
    const response = await addInfringementAPI({ titleID, data });
    return response;
});

const addAction = createAsyncThunk('title/addAction', async ({ titleID, infringementID, actionData }) => {
    const response = await addActionAPI({ titleID, infringementID, data: actionData });
    return response;
});

const updateAction = createAsyncThunk('title/updateAction', async ({ titleID, infringementID, actionID, data }) => {
    const response = await updateActionAPI({ titleID, infringementID, actionID, data });
    return response;
});

const sendCNDEmail = createAsyncThunk('title/action/cndEmail', async ({ titleID, infringementID, actionID, event }) => {
    const responseCND = await sendCNDEmailAPI({ titleID, infringementID, actionID });
    const responseEvent = await createEventAPI({ data: event });
    return { title: responseCND, event: responseEvent };
});

const sendCND2Email = createAsyncThunk('title/action/cnd2Email', async ({ titleID, infringementID, actionID, event }) => {
    const responseCND = await sendCND2EmailAPI({ titleID, infringementID, actionID });
    const responseEvent = await createEventAPI({ data: event });
    return { title: responseCND, event: responseEvent };
});

const sendAttorneyEmail = createAsyncThunk('title/action/attorney/email/1', async ({ titleID, infringementID, actionID, event }) => {
    const responseAttorney = await sendAttorneyEmailAPI({ titleID, infringementID, actionID });
    const responseEvent = await createEventAPI({ data: event });
    return { title: responseAttorney, event: responseEvent };
});

const sendAttorneyEmail2 = createAsyncThunk('title/action/attorney/email/2', async ({ titleID, infringementID, actionID, event }) => {
    const responseAttorney = await sendAttorneyEmail2API({ titleID, infringementID, actionID });
    const responseEvent = await createEventAPI({ data: event });
    return { title: responseAttorney, event: responseEvent };
});

const sendDMCAEmail = createAsyncThunk('title/action/dmcaEmail', async ({ titleID, infringementID, actionID }) => {
    const response = await sendDMCAEmailAPI({ titleID, infringementID, actionID });
    return response;
});

const addActionNote = createAsyncThunk('title/action/note', async ({ titleID, infringementID, actionID, data }) => {
    const response = await addActionNoteAPI({ titleID, infringementID, actionID, data });
    return response;
});

const createTitlesFromFile = createAsyncThunk('title/createFromFile', async ({ projectID, titleType, files }) => {
    // Get s3 upload URLs
    const promises1 = [];
    for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        const fileName = file.name;
        const path = 'titles';
        const mimeType = file.type;
        promises1.push(getFileURLAPI({ fileName, path, mimeType, fileRef: i.toString() }));
    }
    const awsURLs = await Promise.all(promises1);
    // New Titles
    const newTitles = [];
    // Upload files
    const promises2 = [];
    awsURLs.forEach((response) => {
        const { fileRef, url } = response;
        const file = files[parseInt(fileRef, 10)];
        const newTitle = {};
        newTitle.name = file.name.split('.')[0];
        newTitle.fileName = file.name;
        if (titleType) {
            newTitle.type = titleType;
        } else if (file.type.includes('jpeg') || file.type.includes('png')) {
            newTitle.type = '0';
        } else if (file.type.includes('pdf') || file.type.includes('msword')) {
            newTitle.type = '20';
        } else if (file.type.includes('audio')) {
            newTitle.type = '30';
        } else if (file.type.includes('video')) {
            newTitle.type = '10';
        } else {
            newTitle.type = '99';
        }
        newTitle.location = url.split('?')[0];
        newTitles.push(newTitle);
        promises2.push(uploadFileWithURLAPI({ url, file }));
    });
    await Promise.all(promises2);
    const response = await createBulkTitlesAPI({ projectID, data: newTitles });
    return response;
});

const refreshTitle = createAsyncThunk('title/refresh', async ({ id }) => {
    const response = await getTitleAPI({ id });
    return response;
});

const getAdminTitle = createAsyncThunk('title/get', async ({ id, orgID }) => {
    const response = await getAdminTitleAPI({ id, orgID });
    return response;
});

const updateAdminTitles = createAsyncThunk('title/admin/updateTitles', async ({ orgID, data }) => {
    const response = await updateAdminTitleBulkAPI({ orgID, data });
    return response;
});

const getTitleList = createAsyncThunk('title/list', async (projectID) => {
    const response = await getTitleListAPI(projectID);
    return response;
});

const updateTitle = createAsyncThunk('title/update', async ({ id, data }) => {
    const response = await updateTitleAPI({ id, data });
    return response;
});

const deleteTitle = createAsyncThunk('title/delete', async ({ id }) => {
    const response = await deleteTitleAPI({ id });
    return response;
});

const bulkUpdateTitles = createAsyncThunk('title/bulkUpdate', async ({ titles, data }) => {
    const response = await updateBulkTitlesAPI({ titles, data });
    return response;
});

const uploadTitleFile = createAsyncThunk('title/uploadFile', async ({ id, file }) => {
    // Get URL and upload file
    const uploadURL = await getFileURLAPI({ fileName: file.name, path: 'titles', mimeType: file.type });
    await uploadFileWithURLAPI({ url: uploadURL.url, file });

    // Now update Title
    const data = { location: uploadURL.url.split('?')[0], fileName: file.name };
    const responseTitle = await updateTitleAPI({ id, data });
    return responseTitle;
});

const initialState = {
    openUploadModal: false,
    initialized: false,
    updating: false,
    titles: []
};

const titleSlice = createSlice({
    name: 'title',
    initialState,
    reducers: {
        create(state, action) {
            state.titles.push(action.payload.data);
        },
        change(state, action) {
            state.titles = state.titles.map((title) => {
                if (title.id === action.payload.data.id) {
                    return { ...title, ...action.payload.data };
                }
                return title;
            });
        },
        bulkChange(state, action) {
            const titlesUpdated = action.payload.map((item) => item.id);
            console.log('titlesUpdated', titlesUpdated);
            state.titles = [...state.titles.filter((t) => !titlesUpdated.includes(t.id)), ...action.payload];
        },
        showUploadModal(state, action) {
            state.openUploadModal = action.payload.open;
        },
        logout(state) {
            state.initialized = false;
            state.titles = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdminTitle.fulfilled, (state, action) => {
                const titleID = action.payload.id;
                const existingTitle = state.titles.find((title) => title.id === titleID);
                if (existingTitle) {
                    state.titles = state.titles.map((title) => {
                        if (title.id === titleID) {
                            return { ...title, ...action.payload };
                        }
                        return title;
                    });
                } else {
                    state.titles.push(action.payload);
                }
            })
            .addCase(createTitle.fulfilled, (state, action) => {
                state.titles.push(action.payload);
            })
            .addCase(createTitlesFromFile.fulfilled, (state, action) => {
                if (action?.payload?.titles) {
                    state.titles.push(...action.payload.titles);
                    if ((action?.meta?.arg?.files?.length ?? 0) > config.titleFilesThreshold) {
                        state.openUploadModal = true;
                    }
                }
                if (action?.payload?.isOnline === false) {
                    state.openUploadModal = true;
                }
            })
            .addCase(refreshTitle.fulfilled, (state, action) => {
                const titleID = action.payload.id;
                let existingTitle = state.titles.find((title) => title.id === titleID);
                if (existingTitle) {
                    existingTitle = { ...existingTitle, ...action.payload };
                } else {
                    state.titles.push(action.payload);
                }
            })
            .addCase(updateTitle.fulfilled, (state, action) => {
                state.titles = state.titles.map((title) => {
                    if (title.id === action.payload.id) {
                        return { ...title, ...action.payload };
                    }
                    return title;
                });
            })
            .addCase(updateAdminTitles.fulfilled, (state, action) => {
                state.titles = state.titles.map((title) => {
                    // Part of payload?
                    const updatedTitle = action.payload.titles.find((item) => item.id === title.id);
                    if (updatedTitle) {
                        console.log(`updating title ${updatedTitle.id}`);
                        return { ...title, ...updatedTitle };
                    }
                    return title;
                });
            })
            .addCase(addActionNote.fulfilled, (state, action) => {
                state.titles = state.titles.map((title) => {
                    if (title.id === action.payload.id) {
                        return { ...title, ...action.payload };
                    }
                    return title;
                });
            })
            .addCase(updateAction.fulfilled, (state, action) => {
                state.titles = state.titles.map((title) => {
                    if (title.id === action.payload.id) {
                        return { ...title, ...action.payload };
                    }
                    return title;
                });
            })
            .addCase(addInfringement.fulfilled, (state, action) => {
                state.titles = state.titles.map((title) => {
                    if (title.id === action.payload.id) {
                        return { ...title, ...action.payload };
                    }
                    return title;
                });
            })
            .addCase(addAction.fulfilled, (state, action) => {
                state.titles = state.titles.map((title) => {
                    if (title.id === action.payload.id) {
                        return { ...title, ...action.payload };
                    }
                    return title;
                });
            })
            .addCase(sendCNDEmail.fulfilled, (state, action) => {
                // Update title
                state.titles = state.titles.map((title) => {
                    const titleID = action.meta.arg.titleID;
                    if (title.id === titleID) {
                        return { ...title, ...action.payload.title };
                    }
                    return title;
                });
            })
            .addCase(sendCND2Email.fulfilled, (state, action) => {
                // Update title
                state.titles = state.titles.map((title) => {
                    const titleID = action.meta.arg.titleID;
                    if (title.id === titleID) {
                        return { ...title, ...action.payload.title };
                    }
                    return title;
                });
            })
            .addCase(sendAttorneyEmail.fulfilled, (state, action) => {
                // Update title
                console.log('action-title', action.payload.title);
                const titleID = action.meta.arg.titleID;
                const updatedTitle = action.payload.title;
                state.titles = state.titles.map((title) => {
                    if (title.id === titleID) {
                        return { ...title, ...updatedTitle };
                    }
                    return title;
                });
            })
            .addCase(sendAttorneyEmail2.fulfilled, (state, action) => {
                // Update title
                state.titles = state.titles.map((title) => {
                    const titleID = action.meta.arg.titleID;
                    if (title.id === titleID) {
                        return { ...title, ...action.payload.title };
                    }
                    return title;
                });
            })
            .addCase(sendDMCAEmail.fulfilled, (state, action) => {
                state.titles = state.titles.map((title) => {
                    if (title.id === action.payload.id) {
                        return { ...title, ...action.payload };
                    }
                    return title;
                });
            })
            .addCase(uploadTitleFile.fulfilled, (state, action) => {
                state.titles = state.titles.map((title) => {
                    if (title.id === action.payload.id) {
                        return { ...title, ...action.payload };
                    }
                    return title;
                });
            })
            .addCase(bulkUpdateTitles.fulfilled, (state, action) => {
                if (action.payload.isOnline) {
                    const titlesUpdated = action.meta.arg.titles;
                    state.titles = [...state.titles.filter((t) => !titlesUpdated.includes(t.id)), ...action.payload.titles];
                }
            })
            .addCase(getTitleList.pending, (state, action) => {
                state.updating = true;
            })
            .addCase(getTitleList.fulfilled, (state, action) => {
                state.updating = false;
                const updatedProjID = action.meta.arg;
                if (!updatedProjID) {
                    state.initialized = true;
                    state.titles = [...action.payload];
                } else {
                    // debugger; // eslint-disable-line no-debugger
                    const allOtherTitles = state.titles.filter((title) => title.projectID !== updatedProjID);
                    state.titles = [...allOtherTitles, ...action.payload];
                }
                // state.titles = [...state.titles, ...action.payload];
                // state.titles = [...state.titles.filter((t) => !titlesUpdated.includes(t.id)), ...action.payload.titles];
                // no project specified
                // if (!action.meta.arg) {
                //     state.initialized = true;
                // }
            })
            .addCase(deleteTitle.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.titles = state.titles.filter((item) => item.id !== deletedID);
                }
            })
            .addMatcher(
                (action) => action.type.includes('ccb/update/fulfilled'),
                (state, action) => {
                    const updatedTitleRecord = action.payload.title;
                    if (updatedTitleRecord) {
                        state.titles = state.titles.map((title) => {
                            if (title.id === updatedTitleRecord.id) {
                                return { ...title, ...updatedTitleRecord };
                            }
                            return title;
                        });
                    }
                }
            )
            .addMatcher(
                (action) => action.type.includes('ccb/serve/fulfilled'),
                (state, action) => {
                    const updatedTitleRecord = action.payload.title;
                    if (updatedTitleRecord) {
                        state.titles = state.titles.map((title) => {
                            if (title.id === updatedTitleRecord.id) {
                                return { ...title, ...updatedTitleRecord };
                            }
                            return title;
                        });
                    }
                }
            )
            .addMatcher(
                (action) => action.type.includes('ccb/serve/agent/fulfilled'),
                (state, action) => {
                    const updatedTitleRecord = action.payload.title;
                    if (updatedTitleRecord) {
                        state.titles = state.titles.map((title) => {
                            if (title.id === updatedTitleRecord.id) {
                                return { ...title, ...updatedTitleRecord };
                            }
                            return title;
                        });
                    }
                }
            )
            .addMatcher(
                (action) => action.type.includes('project/delete/fulfilled'),
                (state, action) => {
                    const deletedID = action.meta.arg.id;
                    if (deletedID) {
                        state.titles = state.titles.filter((title) => title.projectID !== deletedID);
                    }
                }
            );
    }
});

const { change, logout, create, bulkChange, showUploadModal } = titleSlice.actions;

const initialized = (state) => state?.title?.initialized;
const updating = (state) => state?.title?.updating;
const titles = (state) => state?.title?.titles;
const unReg = (state) => state?.title?.titles.filter((item) => !item.isReg || item.isReg === 'no');

export {
    refreshTitle,
    getAdminTitle,
    updateAdminTitles,
    getTitleList,
    updateTitle,
    deleteTitle,
    uploadTitleFile,
    bulkUpdateTitles,
    createTitle,
    createTitlesFromFile,
    addInfringement,
    addAction,
    sendCNDEmail,
    sendCND2Email,
    sendAttorneyEmail,
    sendAttorneyEmail2,
    sendDMCAEmail,
    addActionNote,
    updateAction,
    initialized,
    updating,
    titles,
    unReg,
    logout,
    create,
    change,
    bulkChange,
    showUploadModal
};
export default titleSlice.reducer;
