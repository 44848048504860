import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { initialized as initializedTitles, getTitleList } from 'store/slices/titleSlice';
import { initialized as initializedProjects, getProjectList } from 'store/slices/projectSlice';
import { usersInitialized as initializedUsers, getUsers } from 'store/slices/adminSlice';
import { getRegistrationList } from 'store/slices/registrationSlice';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListSubheader, MenuItem, Grid } from '@mui/material';

// project imports
import config from 'config';
import { formatDateMMDDYY } from 'utils/dates';
import { titleText } from 'utils/titleIcon';
import { getRCRegStatus } from 'utils/status';

const SearchPopUp = ({ open, handleClose, searchTerm }) => {
    const dispatch = useDispatch();
    const titlesInitialized = useSelector(initializedTitles);
    const projectsInitialized = useSelector(initializedProjects);
    const usersInitialized = useSelector(initializedUsers);
    const userIsAdmin = useSelector((state) => state?.account?.userIsAdmin);

    // Get Data
    const projectList = useSelector((state) => state?.project?.projects?.filter((p) => p.name?.toLowerCase().includes(searchTerm)));
    const titleList = useSelector((state) => state?.title?.titles?.filter((t) => t.name?.toLowerCase().includes(searchTerm)));
    const registrationList = useSelector((state) =>
        state?.registration?.registrations?.filter((r) => r.regName?.toLowerCase().includes(searchTerm))
    );
    const infringementList = [];
    const infTitles = useSelector((state) => state?.title?.titles?.filter((t) => t.infringements && t.infringements.length > 0));
    infTitles.forEach((title) => {
        title.infringements.forEach((inf, idx) => {
            if (inf.reference?.toLowerCase().includes(searchTerm)) {
                const infData = {
                    titleID: title.id,
                    titleName: title.name,
                    infringementID: idx,
                    reference: inf.reference,
                    createdOn: inf.createdOn,
                    actions: inf.actions || []
                };
                infringementList.push(infData);
            }
        });
    });
    // show all actions for chosen infringements
    const actionList = [];
    infringementList.forEach((inf) => {
        if (inf.actions && inf.actions.length > 0) {
            inf.actions.forEach((action, idx) => {
                const actionData = {
                    titleID: inf.titleID,
                    infringementID: inf.infringementID,
                    actionID: idx,
                    titleName: inf.titleName,
                    createdOn: action.createdOn,
                    type: action.type
                };
                actionList.push(actionData);
            });
        }
    });
    // User data for admin
    const userList = useSelector((state) =>
        state?.admin?.users.filter((user) => (user?.personName || '').toLowerCase().includes(searchTerm) && user.status === 'CONFIRMED')
    );

    const nothingFound =
        titleList.length === 0 &&
        projectList.length === 0 &&
        registrationList.length === 0 &&
        infringementList.length === 0 &&
        userList.length === 0;

    useEffect(() => {
        if (searchTerm) {
            // Check all Data has been loaded
            if (!projectsInitialized) {
                dispatch(getProjectList());
            }
            if (!titlesInitialized) {
                dispatch(getTitleList());
            }
            if (!registrationList) {
                dispatch(getRegistrationList());
            }
            if (userIsAdmin && !usersInitialized) {
                dispatch(getUsers());
            }
        }
    }, [searchTerm, titlesInitialized, projectsInitialized, registrationList, dispatch, userIsAdmin, usersInitialized]);

    const getRegType = (type) => {
        const registrationType = config.regType.find((item) => item.id === type);
        return registrationType ? registrationType.name : '<unknown>';
    };

    const chooseTitle = (selectedTitle) => {
        const url = `/title/${selectedTitle.id}`;
        handleClose({ url });
    };

    const chooseProject = (selectedProject) => {
        const url = `/project/${selectedProject.id}`;
        handleClose({ url });
    };

    const formatTitles = (titles) => {
        if (!titles || titles.length === 0) {
            return null;
        }
        return (
            <Grid item xs={12}>
                <ListSubheader>Titles</ListSubheader>
                {titles.map((title) => (
                    <MenuItem key={title.id} value={title} sx={{ mt: -1 }} onClick={() => chooseTitle(title)}>
                        {title.id} - {title.name} ({titleText(title.type)}), created: {formatDateMMDDYY(title.titleCreationDate)}
                    </MenuItem>
                ))}
            </Grid>
        );
    };

    const formatProjects = (projects) => {
        if (!projects || projects.length === 0) {
            return null;
        }
        return (
            <Grid item xs={12}>
                <ListSubheader>Projects</ListSubheader>
                {projects.map((project, index) => (
                    <MenuItem key={index} sx={{ mt: -1 }} onClick={() => chooseProject(project)}>
                        {project.id} - {project.name} ({titleText(project.type)}), number of titles: {project.numTitles}
                    </MenuItem>
                ))}
            </Grid>
        );
    };

    const chooseRegistration = (selectedRegistration) => {
        const url = `/register/title?id=${selectedRegistration.id}`;
        handleClose({ url });
    };

    const formatRegistrations = (registrations) => {
        if (!registrations || registrations.length === 0) {
            return null;
        }
        return (
            <Grid item xs={12}>
                <ListSubheader>Registrations</ListSubheader>
                {registrations.map((reg, index) => (
                    <MenuItem key={index} sx={{ mt: -1 }} onClick={() => chooseRegistration(reg)}>
                        {reg.id} - {reg.regName} ({getRegType(reg.regType)}), created: {formatDateMMDDYY(reg.createdOn)}, status:
                        {getRCRegStatus({ status: reg.status })}
                    </MenuItem>
                ))}
            </Grid>
        );
    };

    const chooseInfringement = (selectedInfringement) => {
        const url = `assess?title=${selectedInfringement.titleID}&inf=${selectedInfringement.infringementID}`;
        handleClose({ url });
    };

    const formatInfringements = (infringements) => {
        if (!infringements || infringements.length === 0) {
            return null;
        }
        return (
            <Grid item xs={12}>
                <ListSubheader>Infringements</ListSubheader>
                {infringements.map((inf, index) => (
                    <MenuItem key={index} sx={{ mt: -1 }} onClick={() => chooseInfringement(inf)}>
                        {inf.infringementID} - {inf.reference} (title: {inf.titleID} - {inf.titleName}), created:
                        {formatDateMMDDYY(inf.createdOn)}, actions: {inf.actions ? inf.actions.length : 0}
                    </MenuItem>
                ))}
            </Grid>
        );
    };

    const chooseAction = (selectedAction) => {
        const url = `assess?title=${selectedAction.titleID}&inf=${selectedAction.infringementID}`;
        handleClose({ url });
    };

    const formatActions = (actions) => {
        if (!actions || actions.length === 0) {
            return null;
        }
        return (
            <Grid item xs={12}>
                <ListSubheader>Actions</ListSubheader>
                {actions.map((action, index) => (
                    <MenuItem key={index} sx={{ mt: -1 }} onClick={() => chooseAction(action)}>
                        {action.actionID} - {action.type} (title: {action.titleID} - {action.titleName}), created:
                        {formatDateMMDDYY(action.createdOn)}
                    </MenuItem>
                ))}
            </Grid>
        );
    };

    const chooseUser = (user) => {
        const url = '/admin';
        handleClose({ url });
    };

    const formatUsers = (users) => {
        if (!users || users.length === 0) {
            return null;
        }
        return (
            <Grid item xs={12}>
                <ListSubheader>Users</ListSubheader>
                {users.map((user, index) => (
                    <MenuItem key={index} sx={{ mt: -1 }} onClick={() => chooseUser(user)}>
                        {`${user.personName} (${user.type}), confirmed: ${user.status === 'CONFIRMED' ? 'Yes' : 'No'}, active: ${
                            user.active ? 'Yes' : 'No'
                        }`}
                    </MenuItem>
                ))}
            </Grid>
        );
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Search RightsClick™</DialogTitle>
                <DialogContent>
                    <DialogContentText>{nothingFound ? 'Nothing found' : 'Please select desired entity:'}</DialogContentText>
                    <Grid container>
                        {formatProjects(projectList)}
                        {formatTitles(titleList)}
                        {formatRegistrations(registrationList)}
                        {formatInfringements(infringementList)}
                        {formatActions(actionList)}
                        {formatUsers(userList)}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" size="small">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

SearchPopUp.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    searchTerm: PropTypes.string
};

export default SearchPopUp;
