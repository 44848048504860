import { createSlice } from '@reduxjs/toolkit';
import config from 'config';

const initialState = {
    isLoading: false,
    showBackdrop: false,
    backdropMessage: '',
    action: false,
    open: false,
    message: 'Default Message',
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
    },
    variant: '',
    alertSeverity: 'success',
    transition: 'Fade',
    close: true,
    actionButton: false
};

const getSuccessState = ({ state, message }) => {
    const newState = {
        ...state,
        isLoading: false,
        open: true,
        alertSeverity: 'success',
        variant: 'alert',
        message
    };
    return newState;
};

const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        open(state, action) {
            const { payload } = action;
            state.action = !state.action;
            state.open = payload.open ? payload.open : initialState.open;
            state.message = payload.message ? payload.message.toString() : initialState.message;
            state.anchorOrigin = payload.anchorOrigin ? payload.anchorOrigin : initialState.anchorOrigin;
            state.variant = payload.variant ? payload.variant : initialState.variant;
            state.alertSeverity = payload.alertSeverity ? payload.alertSeverity : initialState.alertSeverity;
            state.transition = payload.transition ? payload.transition : initialState.transition;
            state.close = payload.close === false ? payload.close : initialState.close;
            state.actionButton = payload.actionButton ? payload.actionButton : initialState.actionButton;
            state.isLoading = false;
            state.showBackdrop = false;
        },
        startLoading(state, action) {
            state.isLoading = true;
            state.message = undefined;
            state.backdropMessage = undefined;
            state.variant = undefined;
            if (action.payload?.backdrop) {
                state.showBackdrop = true;
            }
        },
        endLoading(state) {
            state.isLoading = false;
            state.showBackdrop = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.includes('event/create/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Creating Event';
                }
            )
            .addMatcher(
                (action) => action.type.includes('account/subscription/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Validating Payment';
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/1/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Sending Email';
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/2/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Sending Email';
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cndEmail/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Sending Email';
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cnd2Email/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Sending Follow-Up Email';
                }
            )
            .addMatcher(
                (action) => action.type.includes('account/deactivate/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Deleting Data';
                }
            )
            .addMatcher(
                (action) => action.type.includes('ccb/create/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Creating CCB Claim';
                }
            )
            .addMatcher(
                (action) => action.type.includes('ccb/serve/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Creating Serve Request';
                }
            )
            .addMatcher(
                (action) => action.type.includes('ccb/serve/agent/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Serving CCB Notice';
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/update/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Updating Title';
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/dmcaEmail/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Sending Email';
                }
            )
            .addMatcher(
                (action) => action.type.includes('registration/createZipFile'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Creating Zip File';
                }
            )
            .addMatcher(
                (action) => action.type.includes('account/subscription/fulfilled'),
                (state) => {
                    state.isLoading = false;
                    state.showBackdrop = false;
                    state.open = true;
                    state.message = 'Welcome! You are now a member of RightsClick™';
                    state.alertSeverity = 'success';
                }
            )
            .addMatcher(
                (action) => action.type.includes('ccb/update/pending'),
                (state) => {
                    state.showBackdrop = true;
                    state.backdropMessage = 'Updating CCB Claim';
                }
            )
            .addMatcher(
                (action) => action.type.includes('admin/syncStripe/pending'),
                (state) => {
                    state.showBackdrop = true;
                    state.backdropMessage = 'Accessing Stripe Pricing Data';
                }
            )
            .addMatcher(
                (action) => action.type.includes('createFromFile/pending'),
                (state, action) => {
                    console.log(action);
                    console.log(action.meta.arg.files.length);

                    const newState = {
                        ...state,
                        isLoading: true,
                        showBackdrop: true,
                        backdropMessage: `Uploading ${action.meta.arg.files.length} Titles`,
                        open: true,
                        alertSeverity: 'success',
                        variant: 'alert',
                        message: 'Background processing: Press Refresh to see updates'
                    };
                    return newState;
                }
            )
            .addMatcher(
                (action) => action.type.includes('admin/getUsers/pending'),
                (state) => {
                    state.showBackdrop = true;
                    state.backdropMessage = 'Fetching Data... please be patient';
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/bulkUpdate/pending'),
                (state) => {
                    state.showBackdrop = true;
                    state.backdropMessage = 'Updating Titles';
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/uploadFile/pending'),
                (state) => {
                    state.showBackdrop = true;
                    state.backdropMessage = 'Uploading File';
                }
            )
            .addMatcher(
                (action) => action.type.includes('project/delete/pending'),
                (state) => {
                    state.showBackdrop = true;
                    state.backdropMessage = 'Deleting Project and Related Titles';
                }
            )
            .addMatcher(
                (action) => action.type.includes('attorney/delete/pending'),
                (state) => {
                    state.showBackdrop = true;
                    state.backdropMessage = 'Deleting Attorney';
                }
            )
            .addMatcher(
                (action) => action.type.includes('event/delete/pending'),
                (state) => {
                    state.showBackdrop = true;
                    state.backdropMessage = 'Deleting Event';
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/delete/pending'),
                (state) => {
                    state.showBackdrop = true;
                    state.backdropMessage = 'Deleting Title';
                }
            )
            .addMatcher(
                (action) => action.type.includes('event/create/fulfilled'),
                (state) => getSuccessState({ state, message: 'Event Created' })
            )
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/1/fulfilled'),
                (state) => getSuccessState({ state, message: 'Your Email has been sent' })
            )
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/2/fulfilled'),
                (state) => getSuccessState({ state, message: 'Your Email has been sent' })
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cndEmail/fulfilled'),
                (state) => getSuccessState({ state, message: 'C&D Email sent' })
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cnd2Email/fulfilled'),
                (state) => getSuccessState({ state, message: 'Follow-Up Email sent' })
            )
            .addMatcher(
                (action) => action.type.includes('ccb/create/fulfilled'),
                (state) => getSuccessState({ state, message: 'CCB Claim Created' })
            )
            .addMatcher(
                (action) => action.type.includes('ccb/admin/update/fulfilled'),
                (state) => getSuccessState({ state, message: 'CCB Claim Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('ccb/serve/fulfilled'),
                (state) => getSuccessState({ state, message: 'Request to serve CCB Notice created' })
            )
            .addMatcher(
                (action) => action.type.includes('title/action/dmcaEmail/fulfilled'),
                (state) => getSuccessState({ state, message: 'DMCA Takedown Notice Sent' })
            )
            .addMatcher(
                (action) => action.type.includes('title/action/note/fulfilled'),
                (state) => getSuccessState({ state, message: 'Note added' })
            )
            .addMatcher(
                (action) => action.type.includes('title/updateAction/fulfilled'),
                (state) => getSuccessState({ state, message: 'Action Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('createFromFile/fulfilled'),
                (state, action) => {
                    // Special case when handling files for title
                    if (action.type.includes('title/createFromFile/fulfilled')) {
                        if ((action?.meta?.arg?.files?.length ?? 0) > config.titleFilesThreshold) {
                            // Reset if creating more than 100 titles from files
                            return { ...initialState };
                        }
                        // Otherwise, return the same state
                        // because this is handled by title slice
                        return state;
                    }
                    if (action.payload.isOnline) {
                        return getSuccessState({ state, message: 'Tiles added' });
                    }
                    const newState = {
                        ...state,
                        isLoading: false,
                        open: true,
                        alertSeverity: 'warning',
                        variant: 'alert',
                        showBackdrop: false,
                        message: 'Background processing: Press Refresh to see updates'
                    };
                    return newState;
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/uploadFile/fulfilled'),
                (state) => getSuccessState({ state, message: 'File uploaded' })
            )
            .addMatcher(
                (action) => action.type.includes('project/update/fulfilled'),
                (state) => getSuccessState({ state, message: 'Project Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('project/delete/fulfilled'),
                (state) => getSuccessState({ state, message: 'Project And Titles Deleted' })
            )
            .addMatcher(
                (action) => action.type.includes('project/createFromImport/fulfilled'),
                (state) =>
                    getSuccessState({
                        state,
                        message:
                            'Project has been created successfully and files have been queued for processing in the background. Kindly click on "Refresh List" after a while'
                    })
            )
            .addMatcher(
                (action) => action.type.includes('title/update/fulfilled'),
                (state) => getSuccessState({ state, message: 'Title Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('title/bulkUpdate/fulfilled'),
                // (state) => getSuccessState({ state, message: 'All Selected Titles Updated' })
                (state, action) => {
                    if (action.payload.isOnline) {
                        return getSuccessState({ state, message: 'All Selected Titles Updated' });
                    }
                    const newState = {
                        ...state,
                        isLoading: false,
                        open: true,
                        alertSeverity: 'warning',
                        variant: 'alert',
                        showBackdrop: false,
                        message: 'Background processing: Press Refresh to see updates'
                    };
                    return newState;
                }
            )
            .addMatcher(
                (action) => action.type.includes('admin/updateTitles/fulfilled'),
                (state, action) => {
                    if (action.payload.isOnline) {
                        return getSuccessState({ state, message: 'All Selected Titles Updated' });
                    }
                    const newState = {
                        ...state,
                        isLoading: false,
                        open: true,
                        alertSeverity: 'warning',
                        variant: 'alert',
                        showBackdrop: false,
                        message: 'Background processing: Press Refresh to see updates'
                    };
                    return newState;
                }
            )
            .addMatcher(
                (action) => action.type.includes('account/deactivate/fulfilled'),
                (state) => getSuccessState({ state, message: 'Goodbye - We are sorry to see you go!' })
            )
            .addMatcher(
                (action) => action.type.includes('title/createInfringement/fulfilled'),
                (state) => getSuccessState({ state, message: 'Infringement Assessment Saved' })
            )
            .addMatcher(
                (action) => action.type.includes('registration/get/fulfilled'),
                (state) => getSuccessState({ state, message: 'Registration Refreshed' })
            )
            .addMatcher(
                (action) => action.type.includes('registration/createZipFile/fulfilled'),
                (state, action) => {
                    if (action.payload.isOnline) {
                        return getSuccessState({ state, message: 'Zip file created - ready to download' });
                    }
                    const newState = {
                        ...state,
                        isLoading: false,
                        open: true,
                        alertSeverity: 'warning',
                        variant: 'alert',
                        showBackdrop: false,
                        message: 'Background processing: Please Check Email'
                    };
                    return newState;
                }
            )
            .addMatcher(
                (action) => action.type.includes('registration/admin/update/fulfilled'),
                (state) => getSuccessState({ state, message: 'Registration Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('ccb/update/fulfilled'),
                (state) => getSuccessState({ state, message: 'Your CCB Claim has been updated' })
            )
            .addMatcher(
                (action) => action.type.includes('ccb/serve/agent/fulfilled'),
                (state) => getSuccessState({ state, message: 'Agent has been served notice via email' })
            )
            .addMatcher(
                (action) => action.type.includes('account/user/email/fulfilled'),
                (state) => getSuccessState({ state, message: 'Validaion Email Sent. Please go to your inbox to check' })
            )
            .addMatcher(
                (action) => action.type.includes('account/user/password/fulfilled'),
                (state) => getSuccessState({ state, message: 'Password successfully changed' })
            )
            .addMatcher(
                (action) => action.type.includes('admin/syncStripe/fulfilled'),
                (state) => getSuccessState({ state, message: 'Stripe Data Imported To RightsClick™' })
            )
            .addMatcher(
                (action) => action.type.includes('attorney/create/fulfilled'),
                (state) => getSuccessState({ state, message: 'Attorney Record Created' })
            )
            .addMatcher(
                (action) => action.type.includes('attorney/updated/fulfilled'),
                (state) => getSuccessState({ state, message: 'Attorney Record Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('title/delete/fulfilled'),
                (state) => getSuccessState({ state, message: 'Title Record Deleted' })
            )
            .addMatcher(
                (action) => action.type.includes('event/delete/fulfilled'),
                (state) => getSuccessState({ state, message: 'Event Deleted' })
            )
            .addMatcher(
                (action) => action.type.includes('attorney/delete/fulfilled'),
                (state) => getSuccessState({ state, message: 'Attorney Deleted' })
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.open = true;
                    state.message = action?.error?.message;
                    state.alertSeverity = 'error';
                    state.isLoading = false;
                    state.showBackdrop = false;
                    state.variant = 'alert';
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    state.isLoading = false;
                    state.showBackdrop = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.open = false;
                    state.message = '';
                    state.isLoading = true;
                }
            );
    }
});
export const isLoading = (state) => state?.status?.isLoading;
export default statusSlice.reducer;
export const { open, startLoading, endLoading } = statusSlice.actions;
