import { configureStore } from '@reduxjs/toolkit';
import { loadState } from './persistState';
import statusReducer from './slices/statusSlice';
import accountReducer from './slices/accountSlice';
import customizationReducer from './slices/customizationSlice';
import projectReducer from './slices/projectSlice';
import titleReducer from './slices/titleSlice';
import registrationReducer from './slices/registrationSlice';
import eventReducer from './slices/eventSlice';
import adminReducer from './slices/adminSlice';
import ccbReducer from './slices/ccbSlice';
import attorneyReducer from './slices/attorneySlice';
import importReducer from './slices/importSlice';
import jobReducer from './slices/jobSlice';

// ==============================|| REDUX - STORE ||============================== //

const persistedState = loadState();

const store = configureStore({
    reducer: {
        account: accountReducer,
        admin: adminReducer,
        attorney: attorneyReducer,
        ccb: ccbReducer,
        customization: customizationReducer,
        event: eventReducer,
        project: projectReducer,
        registration: registrationReducer,
        status: statusReducer,
        title: titleReducer,
        import: importReducer,
        job: jobReducer
    },
    preloadedState: persistedState
});

export default store;
