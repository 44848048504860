import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItems from 'menu-items';
import { adminStatus, isTitan as titanStatus, userData } from 'store/slices/accountSlice';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const isAdmin = useSelector(adminStatus);
    const isTitan = useSelector(titanStatus);
    const user = useSelector(userData);
    const { email } = user || {};

    const navItems = menuItems({ isAdmin, isTitan, email }).items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
