import bookIcon from 'assets/images/icons/bookIcon2.png'; // Done
import danceIcon from 'assets/images/icons/danceIcon2.png'; // Done
import headphonesIcon from 'assets/images/icons/headphonesIcon2.png'; // Done
import jewelryIcon from 'assets/images/icons/jewelryIcon2.png'; // Done
import musicIcon from 'assets/images/icons/musicIcon2.png';
import otherIcon from 'assets/images/icons/otherIcon3.png';
import pictureIcon from 'assets/images/icons/pictureIcon2.png'; // Done
import playIcon from 'assets/images/icons/playIcon2.png'; // Done
import sculptureIcon from 'assets/images/icons/sculptureIcon2.png'; // Done
import softwareIcon from 'assets/images/icons/softwareIcon2.png'; // Done
import videoIcon from 'assets/images/icons/videoIcon2.png'; // Done
import literals from 'literals';

const CCB = literals.CCB;

// prettier-ignore
const config = {
    // Development
    // DOMAIN: 'https://zku5r1t0vf.execute-api.us-east-1.amazonaws.com/', // Deveoplment
    // DOMAIN: 'https://2va721ywvg.execute-api.us-east-1.amazonaws.com/', // Production    
    USCO_URL_STUB: 'https://dmca.copyright.gov/osp/search.html',
    CCB_AGENT_URL: 'https://agent-directory.ccb.gov/',
    CCB_OPT_OUT_URL: 'https://dmca.copyright.gov/osp/search.html',
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'theme1', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    ccbStatus: [
        { id: CCB.REQUESTED, text: 'Submitted to RightsClick™', shortText: 'Requested', color: 'warning' },
        { id: CCB.RC_REVIEWING, text: 'Submission to CCB in progress', shortText: 'RC Reviewing', color: 'warning' },
        { id: CCB.SUBMITTED, text: 'Claim Submitted to CCB', shortText: 'Submitted', color: 'information' },
        { id: CCB.SUBMISSION_REJECTED, text: 'Claim Rejected by CCB', shortText: 'Rejected #1', color: 'error' },
        { id: CCB.SUBMITTED_2, text: 'Submitted to CCB #2', shortText: 'Submitted #2', color: 'information' },
        { id: CCB.SUBMISSION_REJECTED_2, text: 'Rejected by CCB #2', shortText: 'Rejected #2', color: 'error' },
        { id: CCB.SUBMITTED_3, text: 'Submitted to CCB #3', shortText: 'Submitted #2', color: 'information' },
        { id: CCB.NOTICE_RECEIVED, text: 'Initial Notice Received', shortText: 'Notice Received', color: 'success' },
        { id: CCB.SERVE_REQUESTED, text: 'CCB Notice Serve: Requested', shortText: 'Serve Requested', color: 'warning' },
        { id: CCB.SERVE_CONFIRMED, text: 'CCB Notice Serve: Confirmed', shortText: 'Serve Confirmed', color: 'information' },
        { id: CCB.SERVE_IN_PROGRESS, text: 'CCB Notice Serve: In Progress', shortText: 'Serve In Progress', color: 'information' },
        { id: CCB.SERVE_ISSUE, text: 'Initial Notice Received', shortText: 'Notice Received', color: 'warning' },
        { id: CCB.SERVE_FAILED, text: 'Initial Notice Received', shortText: 'Notice Received', color: 'error' },
        { id: CCB.NOTICE_SERVED, text: 'Notice Served', shortText: 'Notice Served', color: 'success' },
        { id: CCB.NOTICE_RESPONSE, text: 'Response received', shortText: 'Response', color: 'information' },
        { id: CCB.DISCOVERY, text: 'Discovery', shortText: 'Discovery', color: 'information' },
        { id: CCB.FILING, text: 'Filing', shortText: 'Filing', color: 'information' },
        { id: CCB.JUDGEMENT, text: 'Judgement', shortText: 'Judgement', color: 'information' },
        { id: CCB.CLOSED, text: 'Closed', shortText: 'Closed', color: 'error' },
        { id: CCB.CLOSED_ERRORS, text: 'Closed - Submission Errors', shortText: 'Closed', color: 'error' }
    ],
    eventPriorities: [
        { id: '0', name: 'Low', color: 'information' },
        { id: '1', name: 'Medium', color: 'success' },
        { id: '2', name: 'High', color: 'error' }
    ],
    rcProductTypes: [
        { id: 0, name: 'Subscription' },
        { id: 1, name: 'Registration' },
        { id: 2, name: 'CCB' }
    ],
    regTypes: [
        { id: '0', name: 'single', feeName: 'regSingle' },
        { id: '1', name: 'singleWFH', feeName: 'regSingleWFH' },
        { id: '2', name: 'group', feeName: 'regGroup' },
        { id: '3', name: 'groupPhoto', feeName: 'regGroupPhoto' },
        { id: '4', name: 'groupPhoto100', feeName: 'regGroupPhoto100' },
        { id: '5', name: 'album', feeName: 'regAlbum' },
        { id: '6', name: 'solw', feeName: 'regSOLW' },
        { id: '7', name: 'singleLC', feeName: 'regSingleLC' },
        { id: '8', name: 'wmtoa', feeName: 'regWMTOA' }
    ],
    subTypes: {
        subInd: '0',
        subEnt: '1'
    },
    actionTypes: [
        { id: 'cnd', shortText: 'C&D', text: 'Cease & Desist Notice', titleText: 'Cease & Desist Notices', verb: 'Send Cease & Desist Notice' },
        { id: 'dmca', shortText: 'DMCA', text: 'DMCA Takedown', titleText: 'DMCA Takedown Requests', verb: 'Request DMCA Takedown' },
        { id: 'ccb', shortText: 'CCB', text: 'CCB Claim', titleText: 'CCB Claims', verb: 'File CCB Claim' },
        { id: 'attorney', shortText: 'Attorney', text: 'Attorney Contact', titleText: 'Attorney Connections', verb: 'Contact an Attorney' }
    ],
    regType: [
        { id: '0', name: 'Single' },
        { id: '1', name: 'Single - WFH' },
        { id: '7', name: 'Single - Limit of Claim' },
        { id: '2', name: 'Group' },
        { id: '3', name: 'Group - Photo' },
        { id: '4', name: 'Group - Photo (Big)' },
        { id: '8', name: 'Joint' }
    ],
    rcRegStatus: [
        { id: '0', text: 'Submitted to RightsClick™', shortText: 'Requested', color: 'orange' },
        { id: '1', text: 'Submission to US Copyright Office in progress', shortText: 'Reviewing', color: 'secondary' },
        { id: '2', text: 'Application Submitted to US Copyright Office', shortText: 'Submitted', color: 'information' },
        { id: '3', text: 'Registration successful', shortText: 'Success', color: 'success' },
        { id: '4', text: 'Registration Cannot Be Processed', shortText: 'Withdrawn', color: 'error' },
        { id: '8', text: 'Query sent to RightsClick™ member', shortText: 'Query', color: 'information' },
        { id: '9', text: 'Response to member query received', shortText: 'Response', color: 'warning' }
    ],
    regStatus: [
        { id: 'no', text: 'Unregistered with US Copyright Office', shortText: 'Unregistered', color: 'error' },
        { id: 'yes', text: 'Registered with US Copyright Office in progress', shortText: 'Registered', color: 'success' },
        { id: 'inProgress', text: 'Submission to US Copyright Office in progress', shortText: 'In Progress', color: 'information' },
        { id: 'requested', text: 'Application Submitted to RightsClick', shortText: 'Requested', color: 'warning' }
    ],
    professions: [
        { title: 'Animator', id: 'animator' },
        { title: 'Architect', id: 'architect' },
        { title: 'Author/Writer', id: 'author' },
        { title: 'Choreographer', id: 'choreographer' },
        { title: 'Composer/Songwriter', id: 'composer' },
        { title: 'Software Developer', id: 'software' },
        { title: 'Film/Video Maker', id: 'videographer' },
        { title: 'Musical Performer/Recording Artist', id: 'singer' },
        { title: 'Podcaster', id: 'podcaster' },
        { title: 'Photographer', id: 'photographer' },
        { title: 'Jewelry Designer', id: 'jewelery' },
        { title: 'Sculptor', id: 'sculptor' },
        { title: 'Visual Artist', id: 'artist' },
        { title: 'Illustrator', id: 'illustrator' },
        { title: 'Other', id: 'other' }
    ],
    titleClassifications: [
        { name: 'work of the visual arts', groupName: 'works of the visual arts', shortName: 'Visual Arts', id: '0' },
        { name: 'motion picture/AV work', groupName: 'motion picture/AV work', shortName: 'Audio/Visual', id: '1' },
        { name: 'literary work', groupName: 'literary works', shortName: 'Literary', id: '2' },
        { name: 'sound recording', groupName: 'sound recordings', shortName: 'Sound', id: '3' },
        { name: 'work of the performing arts', groupName: 'works of the performing arts', shortName: 'Performing Arts', id: '4' },
        { name: 'unknown classification', groupName: 'unknown classification', shortName: 'Unknown', id: '9' }
    ],
    titleTypes: [
        { id: '0', name: 'Photograph(s)', shortName: 'Photograph', icon: pictureIcon, classification: '0' },
        { id: '1', name: 'Graphic Design', shortName: 'Graphic Design', icon: pictureIcon, classification: '0' },
        { id: '2', name: 'Illustration', shortName: 'Illustration', icon: pictureIcon, classification: '0' },
        { id: '3', name: 'Painting', shortName: 'Painting', icon: pictureIcon, classification: '0' },
        { id: '4', name: 'Architectural Drawing(s)', shortName: 'Arch.Drawing', icon: pictureIcon, classification: '0' },
        { id: '5', name: 'Technical Drawing', shortName: 'Tech.Drawing', icon: pictureIcon, classification: '0' },
        { id: '11', name: 'Movie/TV Show/Video', shortName: 'Movie', icon: videoIcon, classification: '1' },
        { id: '20', name: 'Book/Manual (fiction, nonfiction, poetry, guide)', shortName: 'Book', icon: bookIcon, classification: '2' },
        { id: '21', name: 'Single Article/Blog Post', shortName: 'Written Article', icon: bookIcon, classification: '2' },
        { id: '22', name: 'Script - Screen/Stage/Other', shortName: 'Script', icon: playIcon, classification: '4' },
        { id: '30', name: 'Sound Recording', shortName: 'Recording', icon: headphonesIcon, classification: '3' },
        { id: '40', name: 'Musical Composition', shortName: 'Music', icon: musicIcon, classification: '4' },
        { id: '41', name: 'Musical Composition & Sound Recording', shortName: 'Score & Recording', icon: musicIcon, classification: '3' },
        { id: '42', name: 'Musical Score (e.g. for film or theater)', shortName: 'Score', icon: musicIcon, classification: '4' },
        { id: '50', name: 'Sculpture', shortName: 'Sculpture', icon: sculptureIcon, classification: '0' },
        { id: '60', name: 'Jewelry', shortName: 'Jewelry', icon: jewelryIcon, classification: '0' },
        { id: '70', name: 'Choreographic Work', shortName: 'Dance', icon: danceIcon, classification: '4' },
        { id: '80', name: 'Computer Code', shortName: 'Software', icon: softwareIcon, classification: '2' },
        { id: '99', name: 'Other', shortName: 'Other', icon: otherIcon, classification: '0' }
        // { name: 'Architectural Drawing(s)', shortName: 'Arch.Drawing', id: '4', icon: pictureIcon, classification: '0' },
        // { name: 'Book/Manual (fiction, nonfiction, poetry, guide)', shortName: 'Book', id: '20', icon: bookIcon, classification: '2' },
        // { name: 'Choreographic Work', shortName: 'Dance', id: '70', icon: danceIcon, classification: '4' },
        // { name: 'Computer Code', shortName: 'Software', id: '80', icon: softwareIcon, classification: '2' },
        // { name: 'Graphic Design', shortName: 'Graphic Design', id: '1', icon: pictureIcon, classification: '0' },
        // { name: 'Illustration', shortName: 'Illustration', id: '2', icon: pictureIcon, classification: '0' },
        // { name: 'Jewelry', shortName: 'Jewelry', id: '60', icon: jewelryIcon, classification: '0' },
        // { name: 'Movie/TV Show/Video', shortName: 'Movie', id: '11', icon: videoIcon, classification: '1' },
        // { name: 'Musical Composition & Sound Recording', shortName: 'Score & Recording', id: '41', icon: musicIcon, classification: '4' },
        // { name: 'Musical Composition', shortName: 'Music', id: '40', icon: musicIcon, classification: '4' },
        // { name: 'Musical Score (e.g. for film or theater)', shortName: 'Score', id: '42', icon: musicIcon, classification: '4' },
        // { name: 'Painting', shortName: 'Painting', id: '3', icon: pictureIcon, classification: '0' },
        // { name: 'Photograph(s)', shortName: 'Photograph', id: '0', icon: pictureIcon, classification: '0' },
        // { name: 'Sculpture', shortName: 'Sculpture', id: '50', icon: sculptureIcon, classification: '0' },
        // { name: 'Single Article/Blog Post', shortName: 'Written Article', id: '21', icon: bookIcon, classification: '2' },
        // { name: 'Sound Recording', shortName: 'Recording', id: '30', icon: headphonesIcon, classification: '3' },
        // { name: 'Technical Drawing', shortName: 'Tech.Drawing', id: '5', icon: pictureIcon, classification: '0' },
        // { name: 'Other', shortName: 'Other', id: '99', icon: otherIcon }
    ],
    titleFilesThreshold: 50,
    titleDownloadFields: [
        { id: 'name', name: 'Title' },
        { id: 'id', name: 'ID' },
        { id: 'fileName', name: 'File Name' },
        { id: 'altAuthor', name: 'Alt. Author' },
        { id: 'authorAddress', name: 'Alt.  Author Address' },
        { id: 'authorEmail', name: 'Alt. Author Email' },
        { id: 'authorPhone', name: 'Alt. Author Phone' },
        { id: 'isAuthor', name: 'Authorship' },
        { id: 'isAuthorEntity', name: 'Entity Author' },
        { id: 'isPub', name: 'Published ' },
        { id: 'pubDate', name: 'Pub Date' },
        { id: 'publisher', name: 'Publisher' },
        { id: 'regID', name: 'USCO Case Number' },
        { id: 'regNo', name: 'Registration Number' },
        { id: 'numISRC', name: 'ISRC#' },
        { id: 'numISWC', name: 'ISWC #' },
        { id: 'numUPC', name: 'UPC#' },
        { id: 'performers1', name: 'Primary Performer(s)' },
        { id: 'performers2', name: 'Secondary Performer(s)' }
    ],
    countryList: [
        { id: 'US', name: 'United States of America', includeThe: true },
        { id: 'AF', name: 'Afghanistan' },
        { id: 'AX', name: 'Åland Islands' },
        { id: 'AL', name: 'Albania' },
        { id: 'DZ', name: 'Algeria' },
        { id: 'AS', name: 'American Samoa' },
        { id: 'AD', name: 'Andorra' },
        { id: 'AO', name: 'Angola' },
        { id: 'AI', name: 'Anguilla' },
        { id: 'AQ', name: 'Antarctica' },
        { id: 'AG', name: 'Antigua and Barbuda' },
        { id: 'AR', name: 'Argentina' },
        { id: 'AM', name: 'Armenia' },
        { id: 'AW', name: 'Aruba' },
        { id: 'AU', name: 'Australia' },
        { id: 'AT', name: 'Austria' },
        { id: 'AZ', name: 'Azerbaijan' },
        { id: 'BS', name: 'Bahamas', includeThe: true },
        { id: 'BH', name: 'Bahrain' },
        { id: 'BD', name: 'Bangladesh' },
        { id: 'BB', name: 'Barbados' },
        { id: 'BY', name: 'Belarus' },
        { id: 'BE', name: 'Belgium' },
        { id: 'BZ', name: 'Belize' },
        { id: 'BJ', name: 'Benin' },
        { id: 'BM', name: 'Bermuda' },
        { id: 'BT', name: 'Bhutan' },
        { id: 'BO', name: 'Bolivia, Plurinational State of' },
        { id: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
        { id: 'BA', name: 'Bosnia and Herzegovina' },
        { id: 'BW', name: 'Botswana' },
        { id: 'BV', name: 'Bouvet Island' },
        { id: 'BR', name: 'Brazil' },
        { id: 'IO', name: 'British Indian Ocean Territory', includeThe: true },
        { id: 'BN', name: 'Brunei Darussalam' },
        { id: 'BG', name: 'Bulgaria' },
        { id: 'BF', name: 'Burkina Faso' },
        { id: 'BI', name: 'Burundi' },
        { id: 'CV', name: 'Cabo Verde' },
        { id: 'KH', name: 'Cambodia' },
        { id: 'CM', name: 'Cameroon' },
        { id: 'CA', name: 'Canada' },
        { id: 'KY', name: 'Cayman Islands', includeThe: true },
        { id: 'CF', name: 'Central African Republic', includeThe: true },
        { id: 'TD', name: 'Chad' },
        { id: 'CL', name: 'Chile' },
        { id: 'CN', name: 'China' },
        { id: 'CX', name: 'Christmas Island' },
        { id: 'CC', name: 'Cocos (Keeling) Islands', includeThe: true },
        { id: 'CO', name: 'Colombia' },
        { id: 'KM', name: 'Comoros' },
        { id: 'CD', name: 'Congo, Democratic Republic of the' },
        { id: 'CG', name: 'Congo' },
        { id: 'CK', name: 'Cook Islands', includeThe: true },
        { id: 'CR', name: 'Costa Rica' },
        { id: 'CI', name: "Côte d'Ivoire" },
        { id: 'HR', name: 'Croatia' },
        { id: 'CU', name: 'Cuba' },
        { id: 'CW', name: 'Curaçao' },
        { id: 'CY', name: 'Cyprus' },
        { id: 'CZ', name: 'Czechia' },
        { id: 'DK', name: 'Denmark' },
        { id: 'DJ', name: 'Djibouti' },
        { id: 'DM', name: 'Dominica' },
        { id: 'DO', name: 'Dominican Republic', includeThe: true },
        { id: 'EC', name: 'Ecuador' },
        { id: 'EG', name: 'Egypt' },
        { id: 'SV', name: 'El Salvador' },
        { id: 'GQ', name: 'Equatorial Guinea' },
        { id: 'ER', name: 'Eritrea' },
        { id: 'EE', name: 'Estonia' },
        { id: 'SZ', name: 'Eswatini' },
        { id: 'ET', name: 'Ethiopia' },
        { id: 'FK', name: 'Falkland Islands (Malvinas)', includeThe: true },
        { id: 'FO', name: 'Faroe Islands', includeThe: true },
        { id: 'FJ', name: 'Fiji' },
        { id: 'FI', name: 'Finland' },
        { id: 'FR', name: 'France' },
        { id: 'GF', name: 'French Guiana' },
        { id: 'PF', name: 'French Polynesia' },
        { id: 'TF', name: 'French Southern Territories' },
        { id: 'GA', name: 'Gabon' },
        { id: 'GM', name: 'Gambia', includeThe: true },
        { id: 'GE', name: 'Georgia' },
        { id: 'DE', name: 'Germany' },
        { id: 'GH', name: 'Ghana' },
        { id: 'GI', name: 'Gibraltar' },
        { id: 'GR', name: 'Greece' },
        { id: 'GL', name: 'Greenland' },
        { id: 'GD', name: 'Grenada' },
        { id: 'GP', name: 'Guadeloupe' },
        { id: 'GU', name: 'Guam' },
        { id: 'GT', name: 'Guatemala' },
        { id: 'GG', name: 'Guernsey' },
        { id: 'GW', name: 'Guinea-Bissau' },
        { id: 'GN', name: 'Guinea' },
        { id: 'GY', name: 'Guyana' },
        { id: 'HT', name: 'Haiti' },
        { id: 'HM', name: 'Heard Island and McDonald Islands' },
        { id: 'VA', name: 'Holy See' },
        { id: 'HN', name: 'Honduras' },
        { id: 'HK', name: 'Hong Kong' },
        { id: 'HU', name: 'Hungary' },
        { id: 'IS', name: 'Iceland' },
        { id: 'IN', name: 'India' },
        { id: 'ID', name: 'Indonesia' },
        { id: 'IR', name: 'Iran, Islamic Republic of' },
        { id: 'IQ', name: 'Iraq' },
        { id: 'IE', name: 'Ireland' },
        { id: 'IM', name: 'Isle of Man', includeThe: true },
        { id: 'IL', name: 'Israel' },
        { id: 'IT', name: 'Italy' },
        { id: 'JM', name: 'Jamaica' },
        { id: 'JP', name: 'Japan' },
        { id: 'JE', name: 'Jersey' },
        { id: 'JO', name: 'Jordan' },
        { id: 'KZ', name: 'Kazakhstan' },
        { id: 'KE', name: 'Kenya' },
        { id: 'KI', name: 'Kiribati' },
        { id: 'KP', name: "Korea, Democratic People's Republic of" },
        { id: 'KR', name: 'Korea, Republic of' },
        { id: 'KW', name: 'Kuwait' },
        { id: 'KG', name: 'Kyrgyzstan' },
        { id: 'LA', name: "Lao People's Democratic Republic" },
        { id: 'LV', name: 'Latvia' },
        { id: 'LB', name: 'Lebanon' },
        { id: 'LS', name: 'Lesotho' },
        { id: 'LR', name: 'Liberia' },
        { id: 'LY', name: 'Libya' },
        { id: 'LI', name: 'Liechtenstein' },
        { id: 'LT', name: 'Lithuania' },
        { id: 'LU', name: 'Luxembourg' },
        { id: 'MO', name: 'Macao' },
        { id: 'MG', name: 'Madagascar' },
        { id: 'MW', name: 'Malawi' },
        { id: 'MY', name: 'Malaysia' },
        { id: 'MV', name: 'Maldives', includeThe: true },
        { id: 'ML', name: 'Mali' },
        { id: 'MT', name: 'Malta' },
        { id: 'MH', name: 'Marshall Islands', includeThe: true },
        { id: 'MQ', name: 'Martinique' },
        { id: 'MR', name: 'Mauritania' },
        { id: 'MU', name: 'Mauritius' },
        { id: 'YT', name: 'Mayotte' },
        { id: 'MX', name: 'Mexico' },
        { id: 'FM', name: 'Micronesia, Federated States of' },
        { id: 'MD', name: 'Moldova, Republic of' },
        { id: 'MC', name: 'Monaco' },
        { id: 'MN', name: 'Mongolia' },
        { id: 'ME', name: 'Montenegro' },
        { id: 'MS', name: 'Montserrat' },
        { id: 'MA', name: 'Morocco' },
        { id: 'MZ', name: 'Mozambique' },
        { id: 'MM', name: 'Myanmar' },
        { id: 'NA', name: 'Namibia' },
        { id: 'NR', name: 'Nauru' },
        { id: 'NP', name: 'Nepal' },
        { id: 'NL', name: 'Netherlands', includeThe: true },
        { id: 'NC', name: 'New Caledonia' },
        { id: 'NZ', name: 'New Zealand' },
        { id: 'NI', name: 'Nicaragua' },
        { id: 'NE', name: 'Niger' },
        { id: 'NG', name: 'Nigeria' },
        { id: 'NU', name: 'Niue' },
        { id: 'NF', name: 'Norfolk Island' },
        { id: 'MK', name: 'North Macedonia' },
        { id: 'MP', name: 'Northern Mariana Islands' },
        { id: 'NO', name: 'Norway' },
        { id: 'OM', name: 'Oman' },
        { id: 'PK', name: 'Pakistan' },
        { id: 'PW', name: 'Palau' },
        { id: 'PS', name: 'Palestine, State of' },
        { id: 'PA', name: 'Panama' },
        { id: 'PG', name: 'Papua New Guinea' },
        { id: 'PY', name: 'Paraguay' },
        { id: 'PE', name: 'Peru' },
        { id: 'PH', name: 'Philippines', includeThe: true },
        { id: 'PN', name: 'Pitcairn' },
        { id: 'PL', name: 'Poland' },
        { id: 'PT', name: 'Portugal' },
        { id: 'PR', name: 'Puerto Rico' },
        { id: 'QA', name: 'Qatar' },
        { id: 'RE', name: 'Réunion' },
        { id: 'RO', name: 'Romania' },
        { id: 'RU', name: 'Russian Federation', includeThe: true },
        { id: 'RW', name: 'Rwanda' },
        { id: 'BL', name: 'Saint Barthélemy' },
        { id: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
        { id: 'KN', name: 'Saint Kitts and Nevis' },
        { id: 'LC', name: 'Saint Lucia' },
        { id: 'MF', name: 'Saint Martin, (French part)' },
        { id: 'PM', name: 'Saint Pierre and Miquelon' },
        { id: 'VC', name: 'Saint Vincent and the Grenadines' },
        { id: 'WS', name: 'Samoa' },
        { id: 'SM', name: 'San Marino' },
        { id: 'ST', name: 'Sao Tome and Principe' },
        { id: 'SA', name: 'Saudi Arabia' },
        { id: 'SN', name: 'Senegal' },
        { id: 'RS', name: 'Serbia' },
        { id: 'SC', name: 'Seychelles', includeThe: true },
        { id: 'SL', name: 'Sierra Leone' },
        { id: 'SG', name: 'Singapore' },
        { id: 'SX', name: 'Sint Maarten, (Dutch part)' },
        { id: 'SK', name: 'Slovakia' },
        { id: 'SI', name: 'Slovenia' },
        { id: 'SB', name: 'Solomon Islands', includeThe: true },
        { id: 'SO', name: 'Somalia' },
        { id: 'ZA', name: 'South Africa' },
        { id: 'GS', name: 'South Georgia and the South Sandwich Islands' },
        { id: 'SS', name: 'South Sudan' },
        { id: 'ES', name: 'Spain' },
        { id: 'LK', name: 'Sri Lanka' },
        { id: 'SD', name: 'Sudan' },
        { id: 'SR', name: 'Suriname' },
        { id: 'SJ', name: 'Svalbard and Jan Mayen' },
        { id: 'SE', name: 'Sweden' },
        { id: 'CH', name: 'Switzerland' },
        { id: 'SY', name: 'Syrian Arab Republic', includeThe: true },
        { id: 'TW', name: 'Taiwan, Province of China' },
        { id: 'TJ', name: 'Tajikistan' },
        { id: 'TZ', name: 'Tanzania, United Republic of' },
        { id: 'TH', name: 'Thailand' },
        { id: 'TL', name: 'Timor-Leste' },
        { id: 'TG', name: 'Togo' },
        { id: 'TK', name: 'Tokelau' },
        { id: 'TO', name: 'Tonga' },
        { id: 'TT', name: 'Trinidad and Tobago' },
        { id: 'TN', name: 'Tunisia' },
        { id: 'TR', name: 'Turkey' },
        { id: 'TM', name: 'Turkmenistan' },
        { id: 'TC', name: 'Turks and Caicos Islands', includeThe: true },
        { id: 'TV', name: 'Tuvalu' },
        { id: 'UG', name: 'Uganda' },
        { id: 'UA', name: 'Ukraine' },
        { id: 'AE', name: 'United Arab Emirates', includeThe: true },
        { id: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland', includeThe: true },
        { id: 'UM', name: 'United States Minor Outlying Islands', includeThe: true },
        { id: 'UY', name: 'Uruguay' },
        { id: 'UZ', name: 'Uzbekistan' },
        { id: 'VU', name: 'Vanuatu' },
        { id: 'VE', name: 'Venezuela, Bolivarian Republic of' },
        { id: 'VN', name: 'Viet Nam' },
        { id: 'VG', name: 'British Virgin Islands', includeThe: true },
        { id: 'VI', name: 'U.S. Virgin Islands', includeThe: true },
        { id: 'WF', name: 'Wallis and Futuna' },
        { id: 'EH', name: 'Western Sahara', includeThe: true },
        { id: 'YE', name: 'Yemen' },
        { id: 'ZM', name: 'Zambia' },
        { id: 'ZW', name: 'Zimbabwe' }
    ],
    stateList: [
        { 'name': 'Alabama', 'id': 'AL' },
        { 'name': 'Alaska', 'id': 'AK' },
        { 'name': 'Arizona', 'id': 'AZ' },
        { 'name': 'Arkansas', 'id': 'AR' },
        { 'name': 'California', 'id': 'CA' },
        { 'name': 'Colorado', 'id': 'CO' },
        { 'name': 'Connecticut', 'id': 'CT' },
        { 'name': 'Delaware', 'id': 'DE' },
        { 'name': 'District of Columbia', 'id': 'DC' },
        { 'name': 'Florida', 'id': 'FL' },
        { 'name': 'Georgia', 'id': 'GA' },
        { 'name': 'Hawaii', 'id': 'HI' },
        { 'name': 'Idaho', 'id': 'ID' },
        { 'name': 'Illinois', 'id': 'IL' },
        { 'name': 'Indiana', 'id': 'IN' },
        { 'name': 'Iowa', 'id': 'IA' },
        { 'name': 'Kansa', 'id': 'KS' },
        { 'name': 'Kentucky', 'id': 'KY' },
        { 'name': 'Lousiana', 'id': 'LA' },
        { 'name': 'Maine', 'id': 'ME' },
        { 'name': 'Maryland', 'id': 'MD' },
        { 'name': 'Massachusetts', 'id': 'MA' },
        { 'name': 'Michigan', 'id': 'MI' },
        { 'name': 'Minnesota', 'id': 'MN' },
        { 'name': 'Mississippi', 'id': 'MS' },
        { 'name': 'Missouri', 'id': 'MO' },
        { 'name': 'Montana', 'id': 'MT' },
        { 'name': 'Nebraska', 'id': 'NE' },
        { 'name': 'Nevada', 'id': 'NV' },
        { 'name': 'New Hampshire', 'id': 'NH' },
        { 'name': 'New Jersey', 'id': 'NJ' },
        { 'name': 'New Mexico', 'id': 'NM' },
        { 'name': 'New York', 'id': 'NY' },
        { 'name': 'North Carolina', 'id': 'NC' },
        { 'name': 'North Dakota', 'id': 'ND' },
        { 'name': 'Ohio', 'id': 'OH' },
        { 'name': 'Oklahoma', 'id': 'OK' },
        { 'name': 'Oregon', 'id': 'OR' },
        { 'name': 'Pennsylvania', 'id': 'PA' },
        { 'name': 'Rhode Island', 'id': 'RI' },
        { 'name': 'South Carolina', 'id': 'SC' },
        { 'name': 'South Dakota', 'id': 'SD' },
        { 'name': 'Tennessee', 'id': 'TN' },
        { 'name': 'Texas', 'id': 'TX' },
        { 'name': 'Utah', 'id': 'UT' },
        { 'name': 'Vermont', 'id': 'VT' },
        { 'name': 'Virginia', 'id': 'VA' },
        { 'name': 'Washington', 'id': 'WA' },
        { 'name': 'West Virginia', 'id': 'WV' },
        { 'name': 'Wisconsin', 'id': 'WI' },
        { 'name': 'Wyoming', 'id': 'WY' }
    ],
    allowedFileTypes: [
        ".bmp",
        ".dwg",
        ".dwf",
        ".gif",
        ".giff",
        ".jpg",
        ".jpeg",
        ".jfif",
        ".pdf",
        ".pic",
        ".pict",
        ".png",
        ".psd",
        ".pub",
        ".tga",
        ".tif",
        ".tiff",
        ".wmf",
        ".doc",
        ".docx",
        ".html",
        ".pdf",
        ".rtf",
        ".txt",
        ".aif",
        ".aiff",
        ".au",
        ".mp3",
        ".wav",
        ".wma",
        ".mp4",
        ".m4a",
        ".m4p",
        ".avi",
        ".mov",
        ".mpg",
        ".mpeg",
        ".swf",
        ".wmv",
        ".3gp",
        ".3gpp",
        ".mp4",
        ".m4v",
        ".flv",
        ".pps",
        ".pptx",
        ".xls",
        ".xlsx"
    ]
};

export default config;
