/* eslint-disable import/prefer-default-export */
import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

// Event Requests
const getExternalMediaListAPI = async () => {
    const url = `${DOMAIN}import/list`;
    const response = await apiFetch({ url });
    return response;
};

export { getExternalMediaListAPI };
