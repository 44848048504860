import { useSelector } from 'react-redux';

// material-ui
import logo from 'assets/images/rcLogo.png';
import titanLogo from 'assets/images/titanLogo.png';

// Project imports
import { userData } from 'store/slices/accountSlice';

// ===========================|| LOGO ||=========================== //

const Logo = () => {
    const { isTitan } = useSelector(userData);
    if (isTitan) {
        return <img src={titanLogo} alt="RightsClick" width="80" />;
    }
    return <img src={logo} alt="RightsClick" width="160" />;
};

export default Logo;
