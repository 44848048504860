// material-ui
import Chip from 'ui-component/extended/Chip';

import config from 'config';

const formatRegStatus = (status) => {
    // if (!status) return null;
    switch (status) {
        case 'inProgress':
            return <Chip chipcolor="warning" label="Pending" size="small" />;
        case 'yes':
            return <Chip chipcolor="success" label="Registered" size="small" />;
        case 'no':
            return <Chip chipcolor="error" label="Unregistered" size="small" />;
        case 'requested':
            return <Chip chipcolor="information" label="Requested" size="small" />;
        default:
            return <Chip chipcolor="error" label="No Status" size="small" />;
    }
};

const getRCRegStatus = ({ status, isShort = true }) => {
    const configRecord = config.rcRegStatus.find((item) => item.id === status);
    if (!configRecord) {
        return 'Invalid Status';
    }
    return isShort ? configRecord.shortText : configRecord.text;
};

const formatRCRegStatus = ({ status, isSmall = false, isShort = false }) => {
    const configRecord = config.rcRegStatus.find((item) => item.id === status) || {};
    const { color = 'error', text = '<undefined>', shortText = '<undefined>' } = configRecord;
    if (isSmall) {
        if (isShort) {
            return <Chip chipcolor={color} label={shortText} size="small" />;
        }
        return <Chip chipcolor={color} label={text} size="small" />;
    }
    if (isSmall) {
        return <Chip chipcolor={color} label={shortText} />;
    }
    return <Chip chipcolor={color} label={text} />;
};

const getCCBStatus = (status) => {
    const configRecord = config.ccbStatus.find((item) => item.id === status) || {};
    const { color = 'error', shortText = '<undefined>' } = configRecord;
    return <Chip chipcolor={color} label={shortText} />;
};

const getActionStatus = (action) => {
    if (action && action.type === 'ccb') {
        return getCCBStatus(action.status);
    }
    // TODO: Other actions statuses
    return action.status;
};

export { formatRCRegStatus, formatRegStatus, getActionStatus, getCCBStatus, getRCRegStatus };
