// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconList,
    IconListCheck,
    IconListDetails,
    IconAlertOctagon,
    IconCertificate,
    IconTools,
    IconCalendarEvent,
    IconFiles,
    IconMail,
    IconDeviceDesktopOff,
    IconBookmark,
    IconUserSearch,
    IconHelp,
    IconBook,
    IconBook2,
    IconNotes,
    IconFileCertificate,
    IconPhotoUp
} from '@tabler/icons';
import { Typography } from '@mui/material';

// ===========================|| USER MENU OPTIONS ||=========================== //

const user = {
    id: 'user-menu',
    type: 'group',
    children: [
        {
            id: 'user-home',
            title: <FormattedMessage id="user-home" />,
            type: 'item',
            url: '/',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'menu-portfolio',
            title: <FormattedMessage id="menu-portfolio" />,
            type: 'item',
            url: '/portfolio',
            icon: IconFiles,
            breadcrumbs: false
        },
        {
            id: 'menu-import',
            title: <FormattedMessage id="menu-import" />,
            type: 'item',
            url: '/import',
            icon: IconPhotoUp,
            breadcrumbs: false
        },
        {
            id: 'menu-register',
            title: <FormattedMessage id="menu-register" />,
            type: 'collapse',
            icon: IconCertificate,
            children: [
                {
                    id: 'menu-register-list',
                    title: <FormattedMessage id="menu-register-list" />,
                    type: 'item',
                    url: '/register/list',
                    icon: IconListCheck,
                    breadcrumbs: false
                },
                {
                    id: 'menu-register-title',
                    title: <FormattedMessage id="menu-register-title" />,
                    type: 'item',
                    url: '/register/select',
                    icon: IconFileCertificate,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'menu-assess',
            title: <FormattedMessage id="menu-assess" />,
            type: 'collapse',
            icon: IconTools,
            children: [
                {
                    id: 'menu-assess-list',
                    title: <FormattedMessage id="menu-assess-list" />,
                    type: 'item',
                    url: '/assess/list',
                    icon: IconList,
                    breadcrumbs: false
                },
                {
                    id: 'menu-register-title',
                    title: <FormattedMessage id="menu-assess-tool" />,
                    type: 'item',
                    url: '/assess',
                    icon: IconTools,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'menu-enforce',
            title: <FormattedMessage id="menu-enforce" />,
            type: 'collapse',
            icon: IconAlertOctagon,
            children: [
                {
                    id: 'menu-enforce-list',
                    title: <FormattedMessage id="menu-enforce-list" />,
                    type: 'item',
                    url: '/enforce/list',
                    icon: IconListDetails,
                    breadcrumbs: false
                },
                {
                    id: 'menu-cnd',
                    title: <FormattedMessage id="menu-cnd" />,
                    type: 'item',
                    url: '/enforce/cnd',
                    icon: IconMail,
                    breadcrumbs: false
                },
                {
                    id: 'menu-takedown',
                    title: <FormattedMessage id="menu-takedown" />,
                    type: 'item',
                    url: '/enforce/dmca',
                    icon: IconDeviceDesktopOff,
                    breadcrumbs: false
                },
                // {
                //     id: 'menu-ccb',
                //     title: <FormattedMessage id="menu-ccb" />,
                //     type: 'item',
                //     url: '/enforce/ccb',
                //     icon: IconBookmark,
                //     breadcrumbs: false
                // },
                // {
                //     id: 'menu-ccb',
                //     title: (
                //         <Typography sx={{ textDecoration: 'line-through' }}>
                //             <FormattedMessage id="menu-ccb" />
                //         </Typography>
                //     ),
                //     type: 'item',
                //     url: '/ccb-placeholder',
                //     icon: IconBookmark,
                //     breadcrumbs: false
                // },
                {
                    id: 'menu-attorney',
                    // title: (
                    //     <Typography sx={{ textDecoration: 'line-through' }}>
                    //         <FormattedMessage id="menu-attorney" />
                    //     </Typography>
                    // ),
                    title: <FormattedMessage id="menu-attorney" />,
                    type: 'item',
                    // url: '/attorney-placeholder',
                    url: '/enforce/attorney',
                    icon: IconUserSearch,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'calendar',
            title: <FormattedMessage id="calendar" />,
            type: 'item',
            url: '/calendar',
            icon: IconCalendarEvent,
            breadcrumbs: false
        },
        {
            id: 'menu-help',
            title: <FormattedMessage id="menu-help" />,
            type: 'collapse',
            icon: IconBook,
            children: [
                {
                    id: 'menu-learn',
                    title: <FormattedMessage id="menu-learn" />,
                    type: 'item',
                    url: '/learn',
                    icon: IconBook2,
                    breadcrumbs: false
                },
                {
                    id: 'menu-registration-guide',
                    title: <FormattedMessage id="menu-registration-guide" />,
                    type: 'item',
                    url: '/registration-guide',
                    icon: IconNotes,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'menu-support',
            title: <FormattedMessage id="menu-support" />,
            type: 'item',
            url: '/support',
            icon: IconHelp,
            breadcrumbs: false
        }
        // {
        //     id: 'menu-test',
        //     title: 'test',
        //     type: 'item',
        //     url: '/test',
        //     breadcrumbs: false
        // }
    ]
};

export default user;
