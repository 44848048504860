import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

// Payment Intent
const createPaymentIntentAPI = async () => {
    const url = `${DOMAIN}payments/intent`;
    const response = await apiFetch({ method: 'POST', url });
    return response;
};

const subscriptionAPI = async ({ data }) => {
    const url = `${DOMAIN}payments/subscription`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

const createSubscriptionAPI = async (data) => {
    const url = `${DOMAIN}payments/subscription`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

const validateDiscountCodeAPI = async ({ code }) => {
    const url = `${DOMAIN}payments/validate?code=${code}`;
    const response = await apiFetch({ url });
    return response;
};

const getSubscriptionPricingAPI = async ({ code }, signal = undefined) => {
    const url = `${DOMAIN}payments/subscription-pricing${code ? `?code=${code}` : ''}`;
    const response = await apiFetch({ url }, signal);
    return response;
};

const deletePaymentMethod = async (data) => {
    const url = `${DOMAIN}payments/delete-payment-method`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

const makeDefaultPaymentMethod = async (data) => {
    const url = `${DOMAIN}payments/set-default-payment-method`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

export {
    createPaymentIntentAPI,
    subscriptionAPI,
    createSubscriptionAPI,
    validateDiscountCodeAPI,
    getSubscriptionPricingAPI,
    deletePaymentMethod,
    makeDefaultPaymentMethod
};
