// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconListCheck, IconCertificate, IconFiles, IconHelp, IconBook, IconFileCertificate } from '@tabler/icons';
import { Typography } from '@mui/material';

// ===========================|| TITAN MENU OPTIONS ||=========================== //

const titan = {
    id: 'user-menu',
    type: 'group',
    children: [
        {
            id: 'user-home',
            title: <FormattedMessage id="user-home" />,
            type: 'item',
            url: '/',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'menu-portfolio',
            title: <FormattedMessage id="menu-portfolio" />,
            type: 'item',
            url: '/portfolio',
            icon: IconFiles,
            breadcrumbs: false
        },
        {
            id: 'menu-register',
            title: <FormattedMessage id="menu-register" />,
            type: 'collapse',
            icon: IconCertificate,
            children: [
                {
                    id: 'menu-register-list',
                    title: <FormattedMessage id="menu-register-list" />,
                    type: 'item',
                    url: '/register/list',
                    icon: IconListCheck,
                    breadcrumbs: false
                },
                {
                    id: 'menu-register-title',
                    title: <FormattedMessage id="menu-register-title" />,
                    type: 'item',
                    url: '/register/select',
                    icon: IconFileCertificate,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'menu-learn',
            title: <FormattedMessage id="menu-learn" />,
            type: 'item',
            url: '/learn',
            icon: IconBook,
            breadcrumbs: false
        }
    ]
};

export default titan;
