import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRegistrationAPI, getRegistrationListAPI, updateRegistrationAPI, createRegistrationZipFileAPI } from 'requests/registration';
import {
    getAdminRegistrationListAPI,
    getAdminRegistrationAPI,
    updateAdminRegistrationAPI,
    updateAdminRegistrationNoteAPI
} from 'requests/admin';

// ==============================|| REDUX STORE: REGISTRATION DATA ||============================== //

const refreshRegistration = createAsyncThunk('registration/get', async ({ id }) => {
    const response = await getRegistrationAPI({ id });
    // Also update all referenced titles
    return response;
});

const getRegistrationList = createAsyncThunk('registration/list', async () => {
    const response = await getRegistrationListAPI();
    return response;
});

const getAdminRegistrationList = createAsyncThunk('registration/admin/list', async () => {
    const response = await getAdminRegistrationListAPI();
    return response;
});

const getAdminRegistration = createAsyncThunk('registration/admin/get', async ({ regID, orgID }) => {
    const response = await getAdminRegistrationAPI({ regID, orgID });
    return response;
});

const updateRegistration = createAsyncThunk('registration/update', async ({ id, data }) => {
    const response = await updateRegistrationAPI({ id, data });
    return response;
});

const updateAdminRegistration = createAsyncThunk('registration/admin/update', async ({ regID, orgID, data }) => {
    const response = await updateAdminRegistrationAPI({ regID, orgID, data });
    return response;
});
const updateAdminRegistrationNote = createAsyncThunk('registration/admin/update/note', async ({ regID, orgID, noteID, data }) => {
    const response = await updateAdminRegistrationNoteAPI({ regID, orgID, noteID, data });
    return response;
});
const createRegistrationZipFile = createAsyncThunk('registration/createZipFile', async ({ orgID, regID, data }) => {
    const response = await createRegistrationZipFileAPI({ orgID, regID, data });
    return response;
});

const initialState = {
    initialized: false,
    registrations: []
};

const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        create(state, action) {
            state.registrations.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.registrations = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(refreshRegistration.fulfilled, (state, action) => {
                // We will never be calling this if the registration doesn't alreadt exist...
                // Consequently code for update is a-OK
                state.registrations = state.registrations.map((registration) => {
                    if (registration.id === action.payload.id) {
                        return { ...registration, ...action.payload };
                    }
                    return registration;
                });
            })
            .addCase(updateRegistration.fulfilled, (state, action) => {
                state.registrations = state.registrations.map((registration) => {
                    if (registration.id === action.payload.id) {
                        return { ...registration, ...action.payload };
                    }
                    return registration;
                });
            })
            .addCase(updateAdminRegistration.fulfilled, (state, action) => {
                state.registrations = state.registrations.map((registration) => {
                    if (registration.id === action.payload.id) {
                        return { ...registration, ...action.payload };
                    }
                    return registration;
                });
            })
            .addCase(updateAdminRegistrationNote.fulfilled, (state, action) => {
                state.registrations = state.registrations.map((registration) => {
                    if (registration.id === action.payload.id) {
                        return { ...registration, ...action.payload };
                    }
                    return registration;
                });
            })
            .addCase(getRegistrationList.fulfilled, (state, action) => {
                state.registrations = [...action.payload];
                state.initialized = true;
            })
            .addCase(getAdminRegistrationList.fulfilled, (state, action) => {
                state.registrations = [...action.payload];
                state.initialized = true;
            })
            .addCase(getAdminRegistration.fulfilled, (state, action) => {
                state.registrations = state.registrations.map((registration) => {
                    if (registration.id === action.payload.id) {
                        return { ...registration, ...action.payload };
                    }
                    return registration;
                });
            })
            .addCase(createRegistrationZipFile.fulfilled, (state, action) => {
                if (action.payload.registration) {
                    state.registrations = state.registrations.map((registration) => {
                        if (registration.id === action.payload.registration.id) {
                            return { ...registration, ...action.payload.registration };
                        }
                        return registration;
                    });
                }
            });
    }
});

const { create, logout } = registrationSlice.actions;
const initialized = (state) => state?.registration?.initialized;
const registrations = (state) => state?.registration?.registrations;

export {
    refreshRegistration,
    getRegistrationList,
    getAdminRegistration,
    updateRegistration,
    initialized,
    registrations,
    create,
    logout,
    getAdminRegistrationList,
    createRegistrationZipFile,
    updateAdminRegistration,
    updateAdminRegistrationNote
};
export default registrationSlice.reducer;
