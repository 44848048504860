import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getProjectAPI,
    getProjectListAPI,
    updateProjectAPI,
    deleteProjectAPI,
    createProjectFromImportAPI,
    addToProjectFromImportAPI
} from 'requests/portfolio';

// ==============================|| REDUX STORE: PROJECT DATA ||============================== //

const getProject = createAsyncThunk('project/get', async ({ id }) => {
    const response = await getProjectAPI({ id });
    return response;
});

const getProjectList = createAsyncThunk('project/list', async () => {
    const response = await getProjectListAPI();
    return response;
});

const updateProject = createAsyncThunk('project/update', async ({ id, data }) => {
    const response = await updateProjectAPI({ id, data });
    return response;
});

const deleteProject = createAsyncThunk('project/delete', async ({ id }) => {
    const response = await deleteProjectAPI({ id });
    return response;
});

const createProjectFromImport = createAsyncThunk('project/createFromImport', async ({ data, files }) => {
    const response = await createProjectFromImportAPI({ data, files });
    return response;
});

const addToProjectFromImport = createAsyncThunk('project/addToFromImport', async ({ projectID, files }) => {
    const response = await addToProjectFromImportAPI({ projectID, files });
    return response;
});

const initialState = {
    initialized: false,
    projects: []
};

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        create(state, action) {
            state.projects.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.projects = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProject.fulfilled, (state, action) => {
                // Needed to clean up a null value - REMOVE
                Object.keys(state.projects).forEach((k) => {
                    if (!state.projects[k] && state.projects[k] !== undefined) {
                        delete state.projects[k];
                    }
                });
                const projectID = action.payload.id;
                let existingProject = state.projects.find((project) => project.id === projectID);
                if (existingProject) {
                    existingProject = { ...existingProject, ...action.payload };
                } else {
                    state.projects.push(action.payload);
                }
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                state.projects = state.projects.map((project) => {
                    if (project.id === action.payload.id) {
                        return { ...project, ...action.payload };
                    }
                    return project;
                });
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.projects = state.projects.filter((item) => item.id !== deletedID);
                }
            })
            .addCase(getProjectList.fulfilled, (state, action) => {
                state.projects = [...action.payload];
                state.initialized = true;
            })
            .addCase(createProjectFromImport.fulfilled, (state, action) => {
                state.projects.push(action.payload.project);
            })
            .addMatcher(
                (action) => action.type.includes('title/list/fulfilled'),
                (state, action) => {
                    const projectID = action.meta?.arg?.projectID;
                    const projRec = state.projects.filter((p) => p.id === projectID)[0];
                    if (projRec) {
                        projRec.initialized = true;
                    }
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/createFromAssess/fulfilled'),
                (state, action) => {
                    if (action.payload.project) {
                        state.projects.push(action.payload.project);
                    }
                }
            );
    }
});

const { create, logout } = projectSlice.actions;
const initialized = (state) => state?.project?.initialized;
const projects = (state) => state?.project?.projects;

export {
    getProject,
    getProjectList,
    updateProject,
    deleteProject,
    createProjectFromImport,
    addToProjectFromImport,
    initialized,
    projects,
    create,
    logout
};
export default projectSlice.reducer;
