import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

// Event Requests
const getAttorneyListAPI = async () => {
    const url = `${DOMAIN}attorney/list`;
    const response = await apiFetch({ url });
    return response;
};
const updateAttorneyAPI = async ({ id, data }) => {
    const url = `${DOMAIN}attorney?id=${id}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const createAttorneyAPI = async ({ data }) => {
    const url = `${DOMAIN}attorney`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};
const deleteAttorneyAPI = async ({ id }) => {
    const url = `${DOMAIN}attorney?id=${id}`;
    const response = await apiFetch({ method: 'DELETE', url });
    return response;
};
const sendAttorneyEmailAPI = async ({ titleID, infringementID, actionID }) => {
    const url = `${DOMAIN}title/action/attorney/email/1?id=${titleID}&inf=${infringementID}&action=${actionID}`;
    const response = await apiFetch({ method: 'POST', url });
    return response;
};

const sendAttorneyEmail2API = async ({ titleID, infringementID, actionID }) => {
    const url = `${DOMAIN}title/action/attorney/email/2?id=${titleID}&inf=${infringementID}&action=${actionID}`;
    const response = await apiFetch({ method: 'POST', url });
    return response;
};

export { getAttorneyListAPI, updateAttorneyAPI, createAttorneyAPI, deleteAttorneyAPI, sendAttorneyEmailAPI, sendAttorneyEmail2API };
