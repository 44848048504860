import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

// Registration Requests
const getRegistrationListAPI = async () => {
    const url = `${DOMAIN}registration/list`;
    const response = await apiFetch({ url });
    return response;
};
const getRegistrationAPI = async ({ id }) => {
    const url = `${DOMAIN}registration?id=${id}`;
    const response = await apiFetch({ url });
    return response;
};
const updateRegistrationAPI = async ({ id, data }) => {
    const url = `${DOMAIN}registration?id=${id}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const createRegistrationAPI = async ({ data, regType }) => {
    const url = `${DOMAIN}registration`;
    const body = JSON.stringify({ data, regType });
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};
const createRegistrationZipFileAPI = async ({ data, orgID, regID }) => {
    const url = `${DOMAIN}admin/registration/zip?orgID=${orgID}&regID=${regID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

export { createRegistrationAPI, updateRegistrationAPI, getRegistrationAPI, getRegistrationListAPI, createRegistrationZipFileAPI };
