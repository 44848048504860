import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateUserAPI, getOrgAPI, getDashboardStatsAPI, validateUserEmailAPI } from 'requests/account';
import { uploadFileAPI } from 'requests/file';
import { createSubscriptionAPI } from 'requests/payments';
import { deactivateAccountAPI, updateUserPasswordAPI } from 'requests/auth';

// ==============================|| REDUX STORE: ACCOUNT DATA ||============================== //

const updateUser = createAsyncThunk('account/user', async ({ data }) => {
    const response = await updateUserAPI({ data });
    return response;
});

const validateUserEmail = createAsyncThunk('account/user/email', async () => {
    const response = await validateUserEmailAPI();
    return response;
});

const updateUserPassword = createAsyncThunk('account/user/password', async ({ oldPassword, newPassword }) => {
    const response = await updateUserPasswordAPI({ oldPassword, newPassword });
    return response;
});

const updateUserAvatar = createAsyncThunk('account/userAvatar', async ({ file, orgID }) => {
    const fileExtenstion = file.name.split('.').pop();
    const name = `avatar.${fileExtenstion}`;
    const response1 = await uploadFileAPI({ name, path: `${orgID}/avatar`, file, isPublic: true });
    const response2 = await updateUserAPI({ data: { photoURL: response1.fileName } });
    return response2;
});
const getOrgData = createAsyncThunk('account/get', async () => {
    const response = await getOrgAPI();
    return response;
});
const getStats = createAsyncThunk('account/getStats', async () => {
    const response = await getDashboardStatsAPI();
    return response;
});
const createSubscription = createAsyncThunk('account/subscription', async ({ paymentMethod, subscriptionData }) => {
    const response = await createSubscriptionAPI({ paymentMethod, subscriptionData });
    return response;
});

const deactivateAccount = createAsyncThunk('account/deactivate', async () => {
    const response = await deactivateAccountAPI();
    return response;
});

const initialState = {
    isLoggedIn: false,
    isAdmin: false,
    orgDataLoaded: false,
    user: {},
    org: {},
    fee: {},
    stats: {}
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        login(state, action) {
            state.isLoggedIn = true;
            state.isAdmin = action.payload.isAdmin;
            state.user = action.payload.userData;
            state.fee = action.payload.feeData;
            state.org = {};
            state.orgDataLoaded = false;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.org = {};
            state.orgDataLoaded = false;
            state.stats = {};
            const keepKeys = ['givenName', 'familyName', 'email'];
            Object.keys(state.user).forEach((key) => keepKeys.includes(key) || delete state.user[key]);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(deactivateAccount.fulfilled, (state) => {
                state.isLoggedIn = false;
                state.orgDataLoaded = false;
                state.user = {};
                state.org = {};
                state.stats = {};
                state.fee = {};
            })
            .addCase(validateUserEmail.fulfilled, (state, action) => {
                state.user = { ...state.user, ...action.payload };
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.user = { ...state.user, ...action.payload };
            })
            .addCase(updateUserAvatar.fulfilled, (state, action) => {
                state.user = { ...state.user, ...action.payload };
            })
            .addCase(createSubscription.fulfilled, (state, action) => {
                state.user.isSubscribed = true;
                state.org = { ...state.org, ...action.payload };
            })
            .addCase(getStats.fulfilled, (state, action) => {
                state.stats = { ...action.payload };
            })
            .addCase(getOrgData.fulfilled, (state, action) => {
                state.org = { ...state.org, ...action.payload };
                state.orgDataLoaded = true;
                // To fix a glitch that invoice data is updated via a webhook andmany notbe present
                if (state.org.subscription && !state.org.invoices) {
                    state.orgDataLoaded = false;
                }
            });
    }
});

const loginStatus = (state) => state?.account?.isLoggedIn;
const adminStatus = (state) => state?.account?.isAdmin;
const subscriptionStatus = (state) => state?.account?.useer?.isSubscribed;
const userData = (state) => state?.account?.user;
const isTitan = (state) => state?.account?.user?.isTitan || false;
const feeData = (state) => state?.account?.fee;
const orgDataLoaded = (state) => state?.account?.orgDataLoaded;
const orgData = (state) => state?.account?.orgData;
const statsData = (state) => state?.account?.stats;
const { login, logout } = accountSlice.actions;

export {
    login,
    logout,
    loginStatus,
    adminStatus,
    updateUser,
    userData,
    feeData,
    updateUserAvatar,
    getOrgData,
    orgDataLoaded,
    orgData,
    createSubscription,
    subscriptionStatus,
    getStats,
    statsData,
    isTitan,
    validateUserEmail,
    deactivateAccount,
    updateUserPassword
};
export default accountSlice.reducer;
