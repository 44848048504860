import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import AppStatus from 'ui-component/extended/AppStatus';
import AuthContext from 'contexts/AuthContext';
import { useAuthProvider } from 'hooks/useAuth';

// ==============================|| APP ||============================== //

const App = () => {
    console.log('>>>>Starting App');
    const customization = useSelector((state) => state.customization);
    const auth = useAuthProvider();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <Locales>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <NavigationScroll>
                            <AuthContext.Provider value={auth}>
                                <Routes />
                                <AppStatus />
                            </AuthContext.Provider>
                        </NavigationScroll>
                    </LocalizationProvider>
                </Locales>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
