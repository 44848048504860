const literals = {
    // All Statuses for CCB Claims
    CCB: {
        REQUESTED: 0,
        RC_REVIEWING: 1,
        SUBMITTED: 2,
        SUBMISSION_REJECTED: 3,
        SUBMITTED_2: 4,
        SUBMISSION_REJECTED_2: 5,
        SUBMITTED_3: 6,
        NOTICE_RECEIVED: 10,
        SERVE_REQUESTED: 11,
        SERVE_CONFIRMED: 12,
        SERVE_IN_PROGRESS: 15,
        SERVE_ISSUE: 16,
        SERVE_FAILED: 19,
        NOTICE_SERVED: 20,
        NOTICE_RESPONSE: 30,
        DISCOVERY: 40,
        FILING: 50,
        JUDGEMENT: 60,
        CLOSED: 90,
        CLOSED_ERRORS: 91
    }
};

export default literals;
