import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Alert, Backdrop, Button, CircularProgress, Fade, Grid, Grow, IconButton, Slide, Typography } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import Loader from 'ui-component/Loader';

// assets
import CloseIcon from '@mui/icons-material/Close';

// animation function
function TransitionSlideLeft(props) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
    return <Grow {...props} />;
}

// animation options
const transition = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade
};

// ==============================|| AppStatus ||============================== //

const AppStatus = () => {
    const [open, setOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [backdropMessage, setBackdropMessage] = useState();
    const statusInitial = useSelector((state) => state.status);

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setOpen(statusInitial.open);
        setShowLoader(statusInitial.isLoading);
        setShowBackdrop(statusInitial.showBackdrop);
        setBackdropMessage(statusInitial.backdropMessage);
    }, [statusInitial]);

    return (
        <>
            {/* default snackbar */}
            {statusInitial.variant === 'default' && (
                <MuiSnackbar
                    anchorOrigin={statusInitial.anchorOrigin}
                    open={open}
                    autoHideDuration={6000}
                    onClose={(e, v) => handleClose(v)}
                    message={statusInitial.message}
                    TransitionComponent={transition[statusInitial.transition]}
                    action={
                        <>
                            <Button color="secondary" size="small" onClick={() => handleClose()}>
                                UNDO
                            </Button>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleClose()}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                />
            )}

            {/* alert snackbar */}
            {statusInitial.variant === 'alert' && (
                <MuiSnackbar
                    TransitionComponent={transition[statusInitial.transition]}
                    anchorOrigin={statusInitial.anchorOrigin}
                    open={open}
                    autoHideDuration={6000}
                    onClose={(e, v) => handleClose(v)}
                >
                    <Alert
                        variant="filled"
                        severity={statusInitial.alertSeverity}
                        sx={{
                            bgcolor: `${statusInitial.alertSeverity}.dark`,
                            color: statusInitial.alertSeverity === 'warning' ? 'grey.800' : '#ffffff'
                        }}
                        action={
                            <>
                                {statusInitial.actionButton !== false && (
                                    <Button color="secondary" size="small" onClick={() => handleClose()}>
                                        UNDO
                                    </Button>
                                )}
                                {statusInitial.close !== false && (
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleClose()}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </>
                        }
                    >
                        {statusInitial.message}
                    </Alert>
                </MuiSnackbar>
            )}
            {showLoader && <Loader />}
            {showBackdrop && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showBackdrop}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Typography variant="h3" sx={{ color: 'white', pb: 2 }}>
                            {backdropMessage}
                        </Typography>
                        <CircularProgress color="inherit" />
                    </Grid>
                </Backdrop>
            )}
        </>
    );
};

export default AppStatus;
