import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/authentication/login')));
const TitanLogin = Loadable(lazy(() => import('views/authentication/login/TitanLogin')));
const AuthSignUp = Loadable(lazy(() => import('views/authentication/sign-up/SignUp')));
const AdminSignUp = Loadable(lazy(() => import('views/authentication/sign-up/AdminSignUp')));
const TitanSignUp = Loadable(lazy(() => import('views/authentication/sign-up/TitanSignUp')));
const AuthRegister = Loadable(lazy(() => import('views/authentication/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('views/authentication/ForgotPassword')));
const AuthCheckMail = Loadable(lazy(() => import('views/authentication/CheckMail')));
const AuthResetPassword = Loadable(lazy(() => import('views/authentication/ResetPassword')));
const AuthForgotPasswordConfirm = Loadable(lazy(() => import('views/authentication/ForgotPasswordConfirm')));
const AuthCodeVerification = Loadable(lazy(() => import('views/authentication/CodeVerification')));
const TermsOfUse = Loadable(lazy(() => import('views/authentication/Terms')));

// ===========================|| AUTH ROUTING ||=========================== //
const LoginRoutes = {
    path: '/login',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/login/titan',
            element: <TitanLogin />
        },
        {
            path: '/login/sign-up',
            element: <AuthSignUp />
        },
        {
            path: '/login/sign-up/titan',
            element: <TitanSignUp />
        },
        {
            path: '/login/register/old',
            element: <AuthRegister />
        },
        {
            path: '/login/check-mail',
            element: <AuthCheckMail />
        },
        {
            path: '/login/reset-password',
            element: <AuthResetPassword />
        },
        {
            path: '/login/forgot-password',
            element: <AuthForgotPassword />
        },
        {
            path: '/login/forgot-password/confirm',
            element: <AuthForgotPasswordConfirm />
        },
        {
            path: '/login/verify',
            element: <AuthCodeVerification />
        },
        {
            path: '/login/admin/signup',
            element: <AdminSignUp />
        },
        {
            path: '/login/terms',
            element: <TermsOfUse />
        }
    ]
};

export default LoginRoutes;
