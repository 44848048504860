import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createCCBAPI, getCCBAPI, updateCCBAPI, getCCBListAPI, serveAgentCCBNoticeAPI, serveCCBNoticeAPI } from 'requests/enforce';
import { getAdminCCBListAPI, updateAdminCCBAPI, refreshAdminCCBAPI } from 'requests/admin';
import { getFileURLAPI, uploadFileWithURLAPI } from 'requests/file';

// ==============================|| REDUX STORE: CCB CLAIM DATA ||============================== //

const createCCBClaim = createAsyncThunk('ccb/create', async ({ titleID, infringementID, data }) => {
    const response = await createCCBAPI({ titleID, infringementID, data });
    return response;
});

const updateCCBClaim = createAsyncThunk('ccb/update', async ({ ccbID, data, noticeFile }) => {
    // First Upload notice file if necessary
    if (noticeFile) {
        const uploadURL = await getFileURLAPI({ fileName: noticeFile.name, path: 'ccb', mimeType: noticeFile.type });
        data.ccbNotice = uploadURL.url.split('?')[0];
        data.noticeReceivedOn = new Date();
        await uploadFileWithURLAPI({ url: uploadURL.url, file: noticeFile });
    }
    // Now update CCB Claim
    const response = await updateCCBAPI({ ccbID, data });
    return response;
});

const serveAgentNotice = createAsyncThunk('ccb/serve/agent', async ({ ccbID, data }) => {
    // Send Email and update CCB Claim
    const response = await serveAgentCCBNoticeAPI({ ccbID, data });
    return response;
});

const serveCCBNotice = createAsyncThunk('ccb/serve', async ({ ccbID, data }) => {
    // Update CCB Claim, Pay abd Creat PRoofServe Job
    const response = await serveCCBNoticeAPI({ ccbID, data });
    return response;
});

const getCCBList = createAsyncThunk('ccb/list', async () => {
    const response = await getCCBListAPI();
    return response;
});

const getCCBRecord = createAsyncThunk('ccb/get', async ({ ccbID }) => {
    const response = await getCCBAPI({ ccbID });
    return response;
});

const getAdminCCBList = createAsyncThunk('ccb/admin/list', async () => {
    const response = await getAdminCCBListAPI();
    return response;
});

const updateAdminCCB = createAsyncThunk('ccb/admin/update', async ({ ccbID, orgID, data }) => {
    const response = await updateAdminCCBAPI({ ccbID, orgID, data });
    return response;
});

const refreshAdminCCB = createAsyncThunk('ccb/admin/refresh', async ({ ccbID, orgID }) => {
    const response = await refreshAdminCCBAPI({ ccbID, orgID });
    return response;
});

const initialState = {
    initialized: false,
    claims: []
};

const ccbSlice = createSlice({
    name: 'ccb',
    initialState,
    reducers: {
        add(state, action) {
            state.claims.push(action.payload.data);
        },
        change(state, action) {
            state.claims = state.claims.map((ccb) => {
                if (ccb.id === action.payload.data.id) {
                    return { ...ccb, ...action.payload.data };
                }
                return ccb;
            });
        },
        logout(state) {
            state.initialized = false;
            state.claims = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCCBList.fulfilled, (state, action) => {
                state.claims = [...action.payload];
                state.initialized = true;
            })
            .addCase(getAdminCCBList.fulfilled, (state, action) => {
                state.claims = [...action.payload];
                state.initialized = true;
            })
            .addCase(getCCBRecord.fulfilled, (state, action) => {
                state.claims = state.claims.map((claim) => {
                    if (claim.id === action.payload.id) {
                        return { ...claim, ...action.payload };
                    }
                    return claim;
                });
            })
            .addCase(updateCCBClaim.fulfilled, (state, action) => {
                const updatedCCBClaimRecord = action.payload.ccb;
                state.claims = state.claims.map((claim) => {
                    if (claim.id === updatedCCBClaimRecord.id) {
                        return { ...claim, ...updatedCCBClaimRecord };
                    }
                    return claim;
                });
            })
            .addCase(serveAgentNotice.fulfilled, (state, action) => {
                const updatedCCBClaimRecord = action.payload.ccb;
                state.claims = state.claims.map((claim) => {
                    if (claim.id === updatedCCBClaimRecord.id) {
                        return { ...claim, ...updatedCCBClaimRecord };
                    }
                    return claim;
                });
            })
            .addCase(serveCCBNotice.fulfilled, (state, action) => {
                const updatedCCBClaimRecord = action.payload.ccb;
                state.claims = state.claims.map((claim) => {
                    if (claim.id === updatedCCBClaimRecord.id) {
                        return { ...claim, ...updatedCCBClaimRecord };
                    }
                    return claim;
                });
            })
            .addCase(updateAdminCCB.fulfilled, (state, action) => {
                state.claims = state.claims.map((claim) => {
                    if (claim.id === action.payload.id) {
                        return { ...claim, ...action.payload };
                    }
                    return claim;
                });
            })
            .addCase(refreshAdminCCB.fulfilled, (state, action) => {
                state.claims = state.claims.map((claim) => {
                    if (claim.id === action.payload.id) {
                        return { ...claim, ...action.payload };
                    }
                    return claim;
                });
            })
            .addCase(createCCBClaim.fulfilled, (state, action) => {
                // We need to ad the new CCB record and update the title record
                state.claims.push(action.payload.ccd);
                state.titles = state.titles.map((title) => {
                    if (title.id === action.payload.title.id) {
                        return { ...title, ...action.payload.title };
                    }
                    return title;
                });
            });
    }
});

const { logout, change, add } = ccbSlice.actions;
const initialized = (state) => state?.ccb?.initialized;
const claims = (state) => state?.ccb?.claims;

export {
    createCCBClaim,
    updateCCBClaim,
    serveAgentNotice,
    serveCCBNotice,
    updateAdminCCB,
    refreshAdminCCB,
    add,
    change,
    logout,
    getAdminCCBList,
    getCCBList,
    getCCBRecord,
    initialized,
    claims
};
export default ccbSlice.reducer;
