// third-party
import { format } from 'date-fns';

const formatDate = (textDate) => {
    if (!textDate || textDate.length === 0) {
        return '<date not specified>';
    }
    return format(new Date(textDate), 'MMMM d, yyyy');
};

const formatDateMMDDYY = (textDate) => {
    if (!textDate || textDate.length === 0) {
        return '';
    }
    return `${format(new Date(textDate), 'MM/dd/yy')}`;
};

const formatDateWithTime = (textDate) => {
    if (!textDate || textDate.length === 0) {
        return '<unspecified>';
    }
    return format(new Date(textDate), 'M/d/yy h:mm aaa');
};

const formatDateLongTime = (textDate) => {
    if (!textDate || textDate.length === 0) {
        return '<unspecified>';
    }
    return format(new Date(textDate), 'MMMM d, yyyy - h:mm aaa');
};

const formatDateLong = (textDate) => {
    if (!textDate || textDate.length === 0) {
        return '<date not specified>';
    }
    return format(new Date(textDate), 'EEEE, MMMM d yyyy');
};

const formatDateMMYY = (textDate) => {
    if (!textDate || textDate.length === 0) {
        return '<date not specified>';
    }
    return format(new Date(textDate), 'MMM, yyyy');
};

const dateToUTC = (localDate) => {
    if (!localDate) {
        return undefined;
    }
    // prettier-ignore
    const utcDate = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds(), localDate.getUTCMilliseconds());
    return utcDate;
};

export { formatDate, formatDateWithTime, formatDateMMDDYY, formatDateLong, dateToUTC, formatDateMMYY, formatDateLongTime };
