import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getExternalMediaListAPI } from 'requests/import';

import { createProjectFromImport } from './projectSlice';

// ==============================|| REDUX STORE: IMPORT DATA ||============================== //

const getExternalMediaList = createAsyncThunk('import/list', async () => {
    const response = await getExternalMediaListAPI();
    return response;
});

const initialState = {
    initialized: false,
    data: []
};

const importSlice = createSlice({
    name: 'import',
    initialState,
    reducers: {
        reset(state) {
            state.initialized = false;
            state.events = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getExternalMediaList.pending, (state) => {
                state.data = [];
                state.initialized = false;
            })
            .addCase(getExternalMediaList.fulfilled, (state, action) => {
                state.data = [...action.payload];
                state.initialized = true;
            })
            .addCase(createProjectFromImport.fulfilled, (state, action) => {
                state.data = state.data.filter(({ id }) => !action.payload.files.includes(id));
            });
    }
});

const { reset: resetExternalMediaList } = importSlice.actions;

const initialized = (state) => state?.import?.initialized;
const externalMedia = (state) => state?.import?.data;

export { getExternalMediaList, resetExternalMediaList, initialized, externalMedia };
export default importSlice.reducer;
