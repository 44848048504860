import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

// Action Requests
const addActionAPI = async ({ titleID, infringementID, data }) => {
    const url = `${DOMAIN}title/action?id=${titleID}&inf=${infringementID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

// CCB Requests
const createCCBAPI = async ({ titleID, infringementID, data }) => {
    const url = `${DOMAIN}ccb?titleID=${titleID}&infringementID=${infringementID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};
const getCCBListAPI = async () => {
    const url = `${DOMAIN}ccb/list`;
    const response = await apiFetch({ url });
    return response;
};
const updateCCBAPI = async ({ ccbID, data }) => {
    const url = `${DOMAIN}ccb?id=${ccbID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const getCCBAPI = async ({ ccbID }) => {
    const url = `${DOMAIN}ccb?id=${ccbID}`;
    const response = await apiFetch({ method: 'GET', url });
    return response;
};

const serveAgentCCBNoticeAPI = async ({ ccbID, data }) => {
    const url = `${DOMAIN}ccb/serve/agent?id=${ccbID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

const serveCCBNoticeAPI = async ({ ccbID, data }) => {
    const url = `${DOMAIN}ccb/serve?id=${ccbID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

// Enforcement Action Requests
const sendCNDEmailAPI = async ({ titleID, infringementID, actionID }) => {
    const url = `${DOMAIN}title/action/email/cnd?id=${titleID}&inf=${infringementID}&action=${actionID}`;
    const response = await apiFetch({ method: 'POST', url });
    return response;
};

const sendCND2EmailAPI = async ({ titleID, infringementID, actionID }) => {
    const url = `${DOMAIN}title/action/email/cnd2?id=${titleID}&inf=${infringementID}&action=${actionID}`;
    const response = await apiFetch({ method: 'POST', url });
    return response;
};

const sendDMCAEmailAPI = async ({ titleID, infringementID, actionID }) => {
    const url = `${DOMAIN}title/action/email/dmca?id=${titleID}&inf=${infringementID}&action=${actionID}`;
    const response = await apiFetch({ method: 'POST', url });
    return response;
};

const addActionNoteAPI = async ({ titleID, infringementID, actionID, data }) => {
    const url = `${DOMAIN}title/action/note?id=${titleID}&inf=${infringementID}&action=${actionID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

const updateActionAPI = async ({ titleID, infringementID, actionID, data }) => {
    const url = `${DOMAIN}title/action?id=${titleID}&inf=${infringementID}&action=${actionID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};

export {
    sendCNDEmailAPI,
    sendDMCAEmailAPI,
    addActionNoteAPI,
    sendCND2EmailAPI,
    updateActionAPI,
    addActionAPI,
    createCCBAPI,
    getCCBListAPI,
    updateCCBAPI,
    getCCBAPI,
    serveAgentCCBNoticeAPI,
    serveCCBNoticeAPI
};
