import { useRoutes } from 'react-router-dom';

// project imports
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AdminRoutes from './AdminRoutes';

// ===========================|| ROUTING RENDER ||=========================== //

const ThemeRoutes = () => useRoutes([LoginRoutes, MainRoutes, AdminRoutes]);

export default ThemeRoutes;
