// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDeviceAnalytics, IconArmchair, IconBookmark, IconCoin, IconUsers } from '@tabler/icons';

// ===========================|| ADMIN MENU OPTIONS ||=========================== //

const admin = {
    id: 'admin-menu',
    type: 'group',
    title: 'Admin',
    children: [
        {
            id: 'admin-home',
            title: <FormattedMessage id="admin-home" />,
            type: 'item',
            url: '/admin',
            icon: IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'admin-registration',
            title: <FormattedMessage id="admin-registration" />,
            type: 'item',
            url: '/admin/registrations',
            icon: IconArmchair,
            breadcrumbs: false
        },
        {
            id: 'admin-ccb',
            title: <FormattedMessage id="admin-ccb" />,
            type: 'item',
            url: '/admin/ccbs',
            icon: IconBookmark,
            breadcrumbs: false
        },
        {
            id: 'admin-fees',
            title: <FormattedMessage id="admin-fees" />,
            type: 'item',
            url: '/admin/fees',
            icon: IconCoin,
            breadcrumbs: false
        },
        {
            id: 'admin-attorney',
            title: <FormattedMessage id="admin-attorney" />,
            type: 'item',
            url: '/admin/attorney/list',
            icon: IconUsers,
            breadcrumbs: false
        }
    ]
};

export default admin;
