// project imports
import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

const getFileURLAPI = async ({ fileName, path, mimeType, fileRef }) => {
    const url = `${DOMAIN}file/geturl?name=${fileName}&path=${path}&mimeType=${mimeType}&fileRef=${fileRef}`;
    const response = await apiFetch({ url });
    return response;
};
const getAdminFileURLAPI = async ({ fileName, path, mimeType, fileRef }) => {
    const url = `${DOMAIN}admin/file/geturl?name=${fileName}&path=${path}&mimeType=${mimeType}&fileRef=${fileRef}`;
    const response = await apiFetch({ url });
    return response;
};

const uploadFileWithURLAPI = async ({ url, file }) => {
    const headers = new Headers();
    headers.append('Content-Type', file.type);
    const uploadResponse = await fetch(url, { method: 'PUT', headers, body: file });
    console.log('Upload File:', uploadResponse);
};

const uploadFileAPI = async ({ name, path, file, isPublic }) => {
    const url = `${DOMAIN}file?path=${path}&name=${name}&public=${isPublic}`;
    const response = await apiFetch({ method: 'POST', url, body: file, mimeType: file.type });
    return response;
};

export { getFileURLAPI, uploadFileWithURLAPI, uploadFileAPI, getAdminFileURLAPI };
