import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAttorneyListAPI, updateAttorneyAPI, createAttorneyAPI, deleteAttorneyAPI } from 'requests/attorney';

// ==============================|| REDUX STORE: ATTORNEY DATA ||============================== //

const getAttorneyList = createAsyncThunk('attorney/list', async () => {
    const response = await getAttorneyListAPI();
    return response;
});

const createAttorney = createAsyncThunk('attorney/create', async ({ data }) => {
    const response = await createAttorneyAPI({ data });
    return response;
});

const updateAttorney = createAsyncThunk('attorney/update', async ({ id, data }) => {
    const response = await updateAttorneyAPI({ id, data });
    return response;
});

const deleteAttorney = createAsyncThunk('attorney/delete', async ({ id }) => {
    const response = await deleteAttorneyAPI({ id });
    return response;
});

const initialState = {
    initialized: false,
    attornies: []
};

const attorneySlice = createSlice({
    name: 'attorney',
    initialState,
    reducers: {
        create(state, action) {
            state.attornies.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.attornies = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAttorney.fulfilled, (state, action) => {
                state.attornies.push(action.payload);
            })
            .addCase(updateAttorney.fulfilled, (state, action) => {
                state.attornies = state.attornies.map((attorney) => {
                    if (attorney.id === action.payload.id) {
                        return { ...attorney, ...action.payload };
                    }
                    return attorney;
                });
            })
            .addCase(deleteAttorney.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.attornies = state.attornies.filter((item) => item.id !== deletedID);
                }
            })
            .addCase(getAttorneyList.fulfilled, (state, action) => {
                state.attornies = [...action.payload];
                state.initialized = true;
            });
    }
});

const { create, logout } = attorneySlice.actions;
const initialized = (state) => state?.attorney?.initialized;
const attornies = (state) => state?.attorney?.attornies;

export { getAttorneyList, updateAttorney, createAttorney, deleteAttorney, initialized, attornies, create, logout };
export default attorneySlice.reducer;
