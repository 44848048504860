import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

// Event Requests
const getEventListAPI = async () => {
    const url = `${DOMAIN}event/list`;
    const response = await apiFetch({ url });
    return response;
};
const getEventAPI = async ({ eventID }) => {
    const url = `${DOMAIN}event?id=${eventID}`;
    const response = await apiFetch({ url });
    return response;
};
const updateEventAPI = async ({ id, data }) => {
    const url = `${DOMAIN}event?id=${id}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const createEventAPI = async ({ data }) => {
    const url = `${DOMAIN}event`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};
const deleteEventAPI = async ({ id }) => {
    const url = `${DOMAIN}event?id=${id}`;
    const response = await apiFetch({ method: 'DELETE', url });
    return response;
};

export { getEventListAPI, getEventAPI, updateEventAPI, createEventAPI, deleteEventAPI };
