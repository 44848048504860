import { createSlice } from '@reduxjs/toolkit';

// project imports
import config from 'config';

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    outlinedFilled: config.outlinedFilled,
    navType: config.theme,
    presetColor: config.presetColor,
    locale: config.i18n,
    rtlLayout: config.rtlLayout,
    isTitan: false,
    opened: true
};

const customizationSlice = createSlice({
    name: 'customization',
    initialState,
    reducers: {
        MENU_OPEN(state, action) {
            state.isOpen = [action.payload.id];
        },
        MENU_TYPE(state, action) {
            state.navType = action.payload.navType;
        },
        PRESET_COLORS(state, action) {
            state.presetColor = action.payload.presetColor;
        },
        SET_MENU(state, action) {
            state.opened = action.payload.opened;
        },
        THEME_LOCALE(state, action) {
            state.locale = action.payload.locale;
        },
        THEME_RTL(state, action) {
            state.rtlLayout = action.payload.rtlLayout;
        },
        SET_FONT_FAMILY(state, action) {
            state.fontFamily = action.payload.fontFamily;
        },
        SET_BORDER_RADIUS(state, action) {
            state.borderRadius = action.payload.borderRadius;
        },
        SET_OUTLINED_FILLED(state, action) {
            state.outlinedFilled = action.payload.outlinedFilled;
        },
        SET_TITAN(state) {
            state.isTitan = true;
            state.presetColor = 'theme2';
        },
        SET_DEFAULT(state) {
            state.isTitan = false;
            state.presetColor = 'theme1';
        }
    }
});

export const {
    MENU_OPEN,
    MENU_TYPE,
    SET_MENU,
    PRESET_COLORS,
    THEME_LOCALE,
    THEME_RTL,
    SET_FONT_FAMILY,
    SET_TITAN,
    SET_DEFAULT,
    SET_BORDER_RADIUS,
    SET_OUTLINED_FILLED
} = customizationSlice.actions;

export default customizationSlice.reducer;
