import config from 'config';
import otherIcon from 'assets/images/icons/otherIcon3.png';
import fileAI from 'assets/images/icons/fileAI.png'; // Done
import fileAVI from 'assets/images/icons/fileAVI.png'; // Done
import fileDOC from 'assets/images/icons/fileDOC.png'; // Done
import fileGIF from 'assets/images/icons/fileGIF.png'; // Done
import fileJPG from 'assets/images/icons/fileJPG.png'; // Done
import fileMKV from 'assets/images/icons/fileMKV.png'; // Done
import fileMP3 from 'assets/images/icons/fileMP3.png'; // Done
import filePDF from 'assets/images/icons/filePDF.png'; // Done
import filePNG from 'assets/images/icons/filePNG.png'; // Done
import filePPT from 'assets/images/icons/filePPT.png'; // Done
import filePSD from 'assets/images/icons/filePSD.png'; // Done
import fileSVG from 'assets/images/icons/fileSVG.png'; // Done
import fileTXT from 'assets/images/icons/fileTXT.png'; // Done
import fileXLS from 'assets/images/icons/fileXLS.png'; // Done
import fileZIP from 'assets/images/icons/fileZIP.png'; // Done
import fileOther from 'assets/images/icons/fileOther.png'; // Done

const UNKNOWN = 'Unknown Type';
const UNKNOWN_CLASSIFICATION = '9';

const supportedFileTypes = ['jpg', 'jpeg', 'gif', 'bmp', 'eps', 'ico', 'png', 'tiff'];

const getExtension = (file) => file?.split('.')?.pop()?.toLowerCase() ?? '';

const isImage = (extension) => supportedFileTypes.includes(extension.toLocaleLowerCase());

const checkImage = (file) => {
    if (!file) {
        return fileOther;
    }

    // Check file type is displayable
    const fileExtension = getExtension(file);
    if (isImage(fileExtension)) {
        return file;
    }
    switch (fileExtension) {
        case 'ai':
            return fileAI;
        case 'avi':
            return fileAVI;
        case 'doc':
            return fileDOC;
        case 'docx':
            return fileDOC;
        case 'gif':
            return fileGIF;
        case 'mkv':
            return fileMKV;
        case 'mp3':
            return fileMP3;
        case 'pdf':
            return filePDF;
        case 'ppt':
            return filePPT;
        case 'pptx':
            return filePPT;
        case 'psd':
            return filePSD;
        case 'svg':
            return fileSVG;
        case 'txt':
            return fileTXT;
        case 'xlsx':
            return fileXLS;
        case 'xls':
            return fileXLS;
        case 'zip':
            return fileZIP;
        default:
            return fileOther;
    }
};

const titleIcon = (title = {}) => {
    if (title.thumbnail) {
        // We have a thumbnail - return either that file (if displayable) or an icon
        return checkImage(title.thumbnail);
    }
    if (title.location) {
        // We have a file - return either that file (if displayable) or an icon
        return checkImage(title.location);
    }
    // No file loaded return image for Title type
    const iconData = config.titleTypes.find((item) => item.id === title.type);
    if (!iconData) {
        return otherIcon;
    }
    return iconData.icon || otherIcon;
};

const titleText = (type) => {
    if (!type) {
        return UNKNOWN;
    }
    const titleData = config.titleTypes.find((item) => item.id === type);
    if (!titleData) {
        return UNKNOWN;
    }
    return titleData.shortName || UNKNOWN;
};

const titleClassificationType = (type) => {
    if (!type) {
        return UNKNOWN_CLASSIFICATION;
    }
    const titleData = config.titleTypes.find((item) => item.id === type);
    if (!titleData || !titleData.classification) {
        return UNKNOWN_CLASSIFICATION;
    }
    return titleData.classification;
};

const titleClassification = ({ type, textType = '' }) => {
    const titleData = config.titleTypes.find((item) => item.id === type);
    if (!titleData) {
        return UNKNOWN;
    }
    const classification = config.titleClassifications.find((item) => item.id === titleData.classification);
    if (!classification) {
        return UNKNOWN;
    }
    switch (textType) {
        case 'short':
            return classification.shortName;
        case 'group':
            return classification.groupName;
        default:
            return classification.name;
    }
};

export { getExtension, isImage, titleIcon, titleText, titleClassification, titleClassificationType };
