import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

// Project Requests
const getProjectListAPI = async () => {
    const url = `${DOMAIN}project/list`;
    const response = await apiFetch({ url });
    return response;
};
const getProjectAPI = async ({ projectID }) => {
    const url = projectID ? `${DOMAIN}project?id=${projectID}` : `${DOMAIN}project`;
    const response = await apiFetch({ url });
    return response;
};
const updateProjectAPI = async ({ id, data }) => {
    const url = `${DOMAIN}project?id=${id}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const createProjectAPI = async ({ data }) => {
    const url = `${DOMAIN}project`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};
const deleteProjectAPI = async ({ id }) => {
    const url = `${DOMAIN}project?id=${id}`;
    const response = await apiFetch({ method: 'DELETE', url });
    return response;
};
const createProjectFromImportAPI = async ({ data, files }) => {
    const url = `${DOMAIN}project-import-files`;
    const body = JSON.stringify({
        fields: data,
        files
    });
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};
const addToProjectFromImportAPI = async ({ projectID, files }) => {
    const url = `${DOMAIN}existing-project-import-files`;
    const body = JSON.stringify({
        projectID,
        files
    });
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

// Title Requests
const getTitleListAPI = async (projectID) => {
    const url = projectID ? `${DOMAIN}title/list?projectID=${projectID}` : `${DOMAIN}title/list`;
    const response = await apiFetch({ url });
    return response;
};
const getTitleAPI = async ({ id }) => {
    const url = `${DOMAIN}title?id=${id}`;
    const response = await apiFetch({ url });
    return response;
};
const getAdminTitleAPI = async ({ id, orgID }) => {
    const url = `${DOMAIN}admin/title?orgID=${orgID}&id=${id}`;
    const response = await apiFetch({ url });
    return response;
};
const createTitleAPI = async ({ projectID, data }) => {
    const url = `${DOMAIN}title?projectID=${projectID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};
const createBulkTitlesAPI = async ({ projectID, data }) => {
    const url = `${DOMAIN}titles?projectID=${projectID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};
const updateTitleAPI = async ({ id, data }) => {
    const url = `${DOMAIN}title?id=${id}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const updateBulkTitlesAPI = async ({ titles, data }) => {
    const url = `${DOMAIN}titles`;
    const body = JSON.stringify({ titles, data });
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};
const deleteTitleAPI = async ({ id }) => {
    const url = `${DOMAIN}title?id=${id}`;
    const response = await apiFetch({ method: 'DELETE', url });
    return response;
};

// Infringement Requests
const addInfringementAPI = async ({ titleID, data }) => {
    const url = `${DOMAIN}title/infringement?id=${titleID}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};
const updateInfringementAPI = async ({ titleID, idx, data }) => {
    const url = `${DOMAIN}title/infringement?id=${titleID}&idx=${idx}`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};

export {
    getProjectListAPI,
    getProjectAPI,
    createProjectAPI,
    updateProjectAPI,
    deleteProjectAPI,
    createProjectFromImportAPI,
    addToProjectFromImportAPI,
    getTitleAPI,
    getAdminTitleAPI,
    getTitleListAPI,
    createTitleAPI,
    createBulkTitlesAPI,
    updateTitleAPI,
    updateBulkTitlesAPI,
    deleteTitleAPI,
    addInfringementAPI,
    updateInfringementAPI
};
