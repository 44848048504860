import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEventListAPI, updateEventAPI, createEventAPI, deleteEventAPI } from 'requests/event';

// ==============================|| REDUX STORE: EVENT DATA ||============================== //

const getEventList = createAsyncThunk('event/list', async () => {
    const response = await getEventListAPI();
    return response;
});

const createEvent = createAsyncThunk('event/create', async ({ data }) => {
    const response = await createEventAPI({ data });
    return response;
});

const updateEvent = createAsyncThunk('event/update', async ({ id, data }) => {
    const response = await updateEventAPI({ id, data });
    return response;
});

const deleteEvent = createAsyncThunk('event/delete', async ({ id }) => {
    const response = await deleteEventAPI({ id });
    return response;
});

const initialState = {
    initialized: false,
    events: []
};

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        create(state, action) {
            state.events.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.events = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createEvent.fulfilled, (state, action) => {
                state.events.push(action.payload);
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                state.events = state.events.map((event) => {
                    if (event.id === action.payload.id) {
                        return { ...event, ...action.payload };
                    }
                    return event;
                });
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.events = state.events.filter((item) => item.id !== deletedID);
                }
            })
            .addCase(getEventList.fulfilled, (state, action) => {
                state.events = [...action.payload];
                state.initialized = true;
            })
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/1/fulfilled'),
                (state, action) => {
                    // Nev Event
                    console.log('action-event', action.payload.event);
                    state.events.push(action.payload.event);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/2/fulfilled'),
                (state, action) => {
                    // Nev Event
                    state.events.push(action.payload.event);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cndEmail/fulfilled'),
                (state, action) => {
                    // Nev Event
                    state.events.push(action.payload.event);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cnd2Email/fulfilled'),
                (state, action) => {
                    // Nev Event
                    state.events.push(action.payload.event);
                }
            );
    }
});

const { logout } = eventSlice.actions;
const initialized = (state) => state?.event?.initialized;
const events = (state) => state?.event?.events;

export { getEventList, updateEvent, initialized, events, createEvent, deleteEvent, logout };
export default eventSlice.reducer;
