// project imports
import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

const getBackgroundJobAPI = async (jobId) => {
    const url = `${DOMAIN}background-jobs?jobId=${jobId}`;
    const response = await apiFetch({ url });
    return response;
};

const getBackgroundJobListAPI = async () => {
    const url = `${DOMAIN}background-jobs`;
    const response = await apiFetch({ url });
    return response;
};

const getBackgroundJobListByProjectAPI = async ({ projectId }) => {
    const url = `${DOMAIN}background-jobs?projectId=${projectId}`;
    const response = await apiFetch({ url });
    return response;
};

export { getBackgroundJobAPI, getBackgroundJobListAPI, getBackgroundJobListByProjectAPI };
