// project imports
import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

const getUserListAPI = async () => {
    const url = `${DOMAIN}user/list`;
    const response = await apiFetch({ url });
    return response;
};

const getUserAPI = async (id) => {
    const url = `${DOMAIN}user?id=${id}`;
    const response = await apiFetch({ url });
    return response;
};

const updateUserAPI = async ({ data }) => {
    const url = `${DOMAIN}user`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'PUT', url, body });
    return response;
};

const validateUserEmailAPI = async () => {
    const url = `${DOMAIN}user/email`;
    const response = await apiFetch({ method: 'GET', url });
    return response;
};

const updateUserEmailStatusAPI = async () => {
    const url = `${DOMAIN}user/email`;
    const response = await apiFetch({ method: 'PUT', url });
    return response;
};

const getOrgAPI = async () => {
    const url = `${DOMAIN}org`;
    const response = await apiFetch({ url });
    return response;
};

const getDashboardStatsAPI = async () => {
    const url = `${DOMAIN}dashboard/stats`;
    const response = await apiFetch({ url });
    return response;
};

const sendSupportEmailAPI = async ({ data }) => {
    const url = `${DOMAIN}support/email`;
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body });
    return response;
};

export {
    updateUserAPI,
    getUserAPI,
    getUserListAPI,
    getOrgAPI,
    getDashboardStatsAPI,
    updateUserEmailStatusAPI,
    validateUserEmailAPI,
    sendSupportEmailAPI
};
