import { useContext, useState, useEffect } from 'react';
import AuthContext from 'contexts/AuthContext';
import { useDispatch } from 'react-redux';

// 3rd party
import { Buffer } from 'buffer';

// project imports
import { logout as accountLogout, login as accountLogin } from 'store/slices/accountSlice';
import { logout as projectLogout } from 'store/slices/projectSlice';
import { logout as titleLogout } from 'store/slices/titleSlice';
import { logout as registrationLogout } from 'store/slices/registrationSlice';
import { logout as adminLogout } from 'store/slices/adminSlice';
import { logout as ccbLogout } from 'store/slices/ccbSlice';
import { logout as attorneyLogout } from 'store/slices/attorneySlice';
import { logout as eventLogout } from 'store/slices/eventSlice';
import { SET_TITAN, SET_DEFAULT } from 'store/slices/customizationSlice';

// APIs
import {
    loginAPI,
    loginChangePasswordAPI,
    registerUserAPI,
    registerUserConfirmAPI,
    registerResendCodeAPI,
    forgotPasswordAPI,
    forgotPasswordConfirmAPI
} from 'requests/auth';

const setSession = ({ idToken, refreshToken }) => {
    if (idToken) {
        localStorage.setItem('idToken', idToken);
    } else {
        localStorage.removeItem('idToken');
    }
    if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
    } else {
        localStorage.removeItem('refreshToken');
    }
};

// ==============================|| AUTH HOOKS ||============================== //
const useAuth = () => useContext(AuthContext);

const checkAdmin = (token) => {
    const base64Payload = token.split('.')[1];
    let payload = new Uint8Array();

    try {
        payload = Buffer.from(base64Payload, 'base64');
    } catch (err) {
        throw new Error(`parseJwt# Malformed token: ${err}`);
    }
    const jwtObject = JSON.parse(payload);
    const groups = jwtObject['cognito:groups'];
    if (!groups) {
        return false;
    }
    return groups.includes('Admin');
};

const useAuthProvider = () => {
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [idToken, setIdToken] = useState('');

    console.log('INIT AUTH PROVIDER');

    useEffect(() => {
        const init = async () => {
            try {
                console.log('Check Refresh');
                // After a refresh if we have a valid token => keep calm and carry on
                if (localStorage.getItem('idToken')) {
                    setIsLoggedIn(true);
                    setIdToken(localStorage.getItem('idToken'));
                    return;
                }
                // In all other cases logout
                dispatch(accountLogout());
            } catch (err) {
                console.error(err);
                dispatch(accountLogout());
            }
        };
        init();
    }, [dispatch]);

    const login = async ({ email, password }) => {
        const loginResponse = await loginAPI({ userName: email, password });
        const { idToken, refreshToken, userData, feeData } = loginResponse;
        setSession({ idToken, refreshToken });
        setIsLoggedIn(true);
        const isAdmin = checkAdmin(idToken);
        console.log('>>>>Log in', userData);
        dispatch(accountLogin({ userData, feeData, isAdmin }));
        if (userData.isTitan) {
            console.log('>>>>TITAN');
            dispatch(SET_TITAN());
        } else {
            console.log('>>>>DEFAULT');
            dispatch(SET_DEFAULT());
        }
    };

    const register = async ({ email, password, givenName, familyName, profession }) =>
        registerUserAPI({ email, password, givenName, familyName, profession });

    const registerConfirm = async ({ userName, confirmationCode }) => {
        const response = await registerUserConfirmAPI({ userName, confirmationCode });
        return response;
    };

    const registerResendCode = async ({ userName }) => {
        const response = await registerResendCodeAPI({ userName });
        return response;
    };

    const forgotPassword = async ({ userName }) => {
        const response = await forgotPasswordAPI({ userName });
        return response;
    };
    const forgotPasswordConfirm = async ({ userName, confirmationCode, password }) => {
        const response = await forgotPasswordConfirmAPI({ userName, confirmationCode, password });
        return response;
    };

    const logout = () => {
        setIsLoggedIn(false);
        setSession({});
        dispatch(accountLogout());
        dispatch(projectLogout());
        dispatch(titleLogout());
        dispatch(registrationLogout());
        dispatch(adminLogout());
        dispatch(ccbLogout());
        dispatch(attorneyLogout());
        dispatch(eventLogout());
    };

    const loginChangePassword = async ({ userID, session, password }) => {
        await loginChangePasswordAPI({ userID, session, password });
        return true;
    };

    return {
        forgotPassword,
        forgotPasswordConfirm,
        login,
        loginChangePassword,
        logout,
        register,
        registerConfirm,
        registerResendCode,
        isLoggedIn,
        idToken
    };
};

export default useAuth;
export { useAuthProvider };
