import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AdminGuard from 'utils/route-guard/AdminGuard';

// page routing
const AdminHomePage = Loadable(lazy(() => import('views/admin/AdminHomePage')));
const AdminHomePageV2 = Loadable(lazy(() => import('views/admin/user-list')));
const SettingsPage = Loadable(lazy(() => import('views/settings-page')));
const AdminRegistrationList = Loadable(lazy(() => import('views/admin/registration/AdminRegistrationList')));
const AdminRegistrationListV2 = Loadable(lazy(() => import('views/admin/registration-list')));
const AdminRegistrationPage = Loadable(lazy(() => import('views/admin/registration')));
const AdminRegistrationPageV2 = Loadable(lazy(() => import('views/admin/registration-page')));
const AdminTitlePage = Loadable(lazy(() => import('views/admin/other/AdminTitlePage')));
const AdminCCBList = Loadable(lazy(() => import('views/admin/ccb/AdminCCBList')));
const AdminCCBPage = Loadable(lazy(() => import('views/admin/ccb/AdminCCBPage')));
const AdminFeesPage = Loadable(lazy(() => import('views/admin/fees')));
const AdminAttorneyList = Loadable(lazy(() => import('views/admin/attorney')));
const AdminAttorneyPage = Loadable(lazy(() => import('views/admin/attorney/AdminAttorneyPage')));

// ===========================|| MAIN ROUTING FOR ADMINS ||=========================== //

const AdminRoutes = {
    path: '/admin',
    element: (
        <AdminGuard>
            <MainLayout />
        </AdminGuard>
    ),
    children: [
        {
            path: '/admin',
            element: <AdminHomePage />
        },
        {
            path: '/admin/v2',
            element: <AdminHomePageV2 />
        },
        {
            path: '/admin/settings',
            element: <SettingsPage />
        },
        {
            path: '/admin/registrations',
            element: <AdminRegistrationListV2 />
        },
        // {
        //     path: '/admin/registrations/v2',
        //     element: <AdminRegistrationListV2 />
        // },
        {
            path: '/admin/registration',
            element: <AdminRegistrationPageV2 />,
            children: [{ path: ':orgID', children: [{ path: ':id', element: <AdminRegistrationPage /> }] }]
        },
        // {
        //     path: '/admin/registration/v2',
        //     element: <AdminRegistrationPageV2 />,
        //     children: [{ path: ':orgID', children: [{ path: ':id', element: <AdminRegistrationPage /> }] }]
        // },
        {
            path: '/admin/ccbs',
            element: <AdminCCBList />
        },
        {
            path: '/admin/ccb',
            element: <AdminCCBPage />
        },
        {
            path: '/admin/fees',
            element: <AdminFeesPage />
        },
        {
            path: '/admin/attorney/list',
            element: <AdminAttorneyList />
        },
        {
            path: '/admin/attorney',
            element: <AdminAttorneyPage />,
            children: [{ path: ':id', element: <AdminAttorneyPage /> }]
        },
        {
            path: '/admin/title',
            element: <AdminTitlePage />,
            children: [{ path: ':orgID', children: [{ path: ':id', element: <AdminTitlePage /> }] }]
        }
    ]
};

export default AdminRoutes;
