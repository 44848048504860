import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { getBackgroundJobAPI, getBackgroundJobListAPI, getBackgroundJobListByProjectAPI } from 'requests/job';

const getBackgroundJobList = createAsyncThunk('jobs/list', async () => {
    const response = await getBackgroundJobListAPI();
    return response;
});

const updateBackgroundJobList = createAsyncThunk('jobs/updateList', async () => {
    const response = await getBackgroundJobListAPI();
    return response;
});

const resetBackgroundJobs = createAction('jobs/reset');

const initialState = {
    initialized: false,
    updating: false,
    jobs: {}
};

const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducer: {
        // reset(state) {
        //     state.initialized = false;
        //     state.loading = false;
        //     state.jobs = {};
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBackgroundJobList.pending, (state, action) => {
                state.initialized = false;
            })
            .addCase(getBackgroundJobList.fulfilled, (state, action) => {
                state.initialized = true;
                state.jobs = Object.fromEntries(action.payload.map((job) => [job.jobId, job]));
            })
            .addCase(updateBackgroundJobList.pending, (state, action) => {
                state.updating = true;
            })
            .addCase(updateBackgroundJobList.fulfilled, (state, action) => {
                state.updating = false;
                state.jobs = Object.fromEntries(action.payload.map((job) => [job.jobId, job]));
            })
            .addCase(resetBackgroundJobs, () => initialState);
    }
});

const initialized = (state) => state?.job?.initialized;
const updating = (state) => state?.job?.updating;
const jobs = (state) => state?.job?.jobs;

export { getBackgroundJobList, updateBackgroundJobList, resetBackgroundJobs, initialized, updating, jobs };

export default jobSlice.reducer;
